const WRAPPER_SELECTOR = '.input-group';
const SELECTOR = '.floated-labels';
const ACTIVE_CLASS = 'floated-labels--active';
const INITIALIZED_CLASS = 'floated-labels--initialized';

function addFocus(this: HTMLElement) {
    this.classList.add(ACTIVE_CLASS);
    this.closest(WRAPPER_SELECTOR)?.classList.add(ACTIVE_CLASS);
}

function removeFocus(this: HTMLElement) {
    this.classList.remove(ACTIVE_CLASS);
    this.closest(WRAPPER_SELECTOR)?.classList.remove(ACTIVE_CLASS);
}

function handleFocus(this: HTMLInputElement | HTMLSelectElement) {
    if (this.classList.contains('choices__input')) {
        const select = this.querySelector('select');

        if (select) {
            if ((select && select.value.length === 0) || select.value === select.dataset.placeholder) {
                removeFocus.call(this);
            }
        }
    } else if (this.value.length === 0) {
        removeFocus.call(this);
    }
}

function onChange(this: HTMLInputElement | HTMLSelectElement) {
    addFocus.call(this);
}

function init(container: Element | Document = document) {
    const inputs = Array.from(container.querySelectorAll<HTMLInputElement>(`${SELECTOR}:not(${INITIALIZED_CLASS})`));

    inputs.forEach((input) => {
        input.addEventListener('focus', addFocus);
        input.addEventListener('blur', handleFocus);
        input.addEventListener('input', onChange);
        input.classList.add(INITIALIZED_CLASS);

        input.dispatchEvent(new Event('focus'));
        input.dispatchEvent(new Event('blur'));

        setTimeout(() => {
            if (document.activeElement !== input) {
                input.dispatchEvent(new Event('focus'));
                input.dispatchEvent(new Event('blur'));
            }
        }, 300);

        setTimeout(() => {
            if (document.activeElement !== input) {
                input.dispatchEvent(new Event('focus'));
                input.dispatchEvent(new Event('blur'));
            }
        }, 700);
    });
}

function destroy(container: Element | Document = document) {
    const inputs = Array.from(container.querySelectorAll<HTMLInputElement>(SELECTOR));

    inputs.forEach((input) => {
        input.removeEventListener('focus', addFocus);
        input.removeEventListener('blur', handleFocus);
        input.removeEventListener('input', onChange);
        input.classList.remove(INITIALIZED_CLASS);
    });
}

const _module = { init, destroy };

export default _module;
