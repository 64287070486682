import gsap from 'gsap';
import { ease1 } from '../utils/easings';

export function createInitialBaseTimeline() {
    const headerEls = Array.from(
        document.querySelectorAll('.js-header:not(.header--account) .js-initial-animation__header-el'),
    );
    const tl = gsap.timeline({
        paused: true,
        defaults: { duration: 0.483, ease: ease1 },
        onComplete: () => {
            document.documentElement.classList.add('initial-animation-complete');
        },
    });

    tl.fromTo(
        headerEls,
        { opacity: 0, y: -20 },
        {
            opacity: 1,
            y: 0,
            stagger: 0.067,
            onComplete: () => {
                headerEls.forEach((el) => {
                    el.classList.add('is-animated');
                });
            },
        },
    );

    return tl;
}
