import type { Swiper as SwiperType, SwiperOptions } from 'swiper';
import deepMerge from 'lodash.merge';
import { fetchSwiper } from '../dynamic-modules';
import { executeOnIntersection } from '../utils/execute-on-intersection';
import { withLeadingZero } from '../utils/strings';

export const map = new Map<Element, any>();

function generateNavButton(
    direction: 'prev' | 'next',
    className = 'slider-nav-btn',
    template = `
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 1530" clip-path="url(#clip0_5_347)">
            <path id="Rectangle 364" d="M7 1L3 5L7 9" stroke="white"/>
        </g>
        <defs>
            <clipPath id="clip0_5_347">
                <rect width="10" height="10" fill="white" transform="translate(0 10) rotate(-90)"/>
            </clipPath>
        </defs>
    </svg>
    `,
) {
    const button = document.createElement('button');
    button.className = `${className} ${className}--${direction} js-slider-${direction}`;
    button.innerHTML = template;
    button.setAttribute('aria-label', `${direction === 'prev' ? 'Предыдущий' : 'Следующий'} слайд`);
    return button;
}

export function createNav(swiper: SwiperType, sliderNav?: Element | null, className?: string, template?: string) {
    if (sliderNav) {
        const navigateBackwards = () => {
            swiper.slidePrev();
        };

        const navigateForwards = () => {
            swiper.slideNext();
        };

        const fragment = document.createDocumentFragment();
        const prevButton = generateNavButton('prev', className, template);
        fragment.appendChild(prevButton);
        prevButton.addEventListener('click', navigateBackwards);
        map.set(prevButton, navigateBackwards);

        const nextButton = generateNavButton('next', className, template);
        fragment.appendChild(nextButton);
        nextButton.addEventListener('click', navigateForwards);
        map.set(nextButton, navigateForwards);

        sliderNav.appendChild(fragment);
    }
}

export function destroyNav(containerEl: Element) {
    const prevButton = containerEl.querySelector<HTMLElement>('.js-slider-prev');
    const nextButton = containerEl.querySelector<HTMLElement>('.js-slider-next');

    [prevButton, nextButton].forEach((btn) => {
        if (btn) {
            const listener = map.get(btn);

            if (listener) {
                btn.removeEventListener('click', listener);
                map.delete(btn);
            }
        }
    });
}

export function createCounter(swiper: SwiperType, container?: Element | null, leadingZero = true) {
    if (container) {
        const fragment = document.createDocumentFragment();
        const current = document.createElement('div');
        current.className = 'slider-counter--current';
        current.textContent = leadingZero ? withLeadingZero(swiper.realIndex + 1) : (swiper.realIndex + 1).toString();
        fragment.appendChild(current);

        const total = document.createElement('div');
        total.className = 'slider-counter--total';
        total.textContent = leadingZero
            ? withLeadingZero(
                  swiper.slides.filter((slide) => !slide.classList.contains('swiper-slide-duplicate')).length,
              )
            : swiper.slides.filter((slide) => !slide.classList.contains('swiper-slide-duplicate')).length.toString();
        fragment.appendChild(total);

        swiper.on('slideChange', () => {
            current.textContent = leadingZero
                ? withLeadingZero(swiper.realIndex + 1)
                : (swiper.realIndex + 1).toString();
        });

        container.appendChild(fragment);
    }
}

function init(el: HTMLElement, sliderOptions: SwiperOptions = {}) {
    executeOnIntersection(
        el,
        async () => {
            const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

            if (slides.length > 1) {
                const { Swiper, Lazy } = await fetchSwiper();
                const sliderBlock = el.closest<HTMLElement>('.js-slider-block');
                const sliderNav = sliderBlock?.querySelector<HTMLElement>('.js-slider-nav');
                const slider = new Swiper(
                    el,
                    deepMerge<SwiperOptions, SwiperOptions>(
                        {
                            modules: [Lazy],
                            slidesPerView: 'auto',
                            loopedSlides: 2,
                            loop: true,
                            centeredSlides: true,
                            speed: 500,
                            watchSlidesProgress: true,
                            preloadImages: false,
                            lazy: {
                                loadPrevNext: true,
                                loadPrevNextAmount: 2,
                                checkInView: true,
                            },
                            on: {
                                beforeInit: (swiper) => {
                                    createNav(swiper, sliderNav);
                                },
                                init: (swiper) => {
                                    sliderBlock?.classList.add('slider-block-initialized');

                                    if (
                                        typeof swiper.params.slidesPerView === 'number' &&
                                        slides.length <= swiper.params.slidesPerView
                                    ) {
                                        swiper.disable();
                                    }
                                },
                                destroy: () => {
                                    destroyNav(el);
                                    sliderBlock?.classList.remove('slider-block-initialized');
                                },
                                resize: (swiper) => {
                                    if (
                                        typeof swiper.params.slidesPerView === 'number' &&
                                        slides.length <= swiper.params.slidesPerView
                                    ) {
                                        swiper.disable();
                                    } else {
                                        swiper.enable();
                                    }
                                },
                            },
                        },
                        sliderOptions,
                    ),
                );
                map.set(el, slider);
            }
        },
        { rootMargin: '200px 0px 200px 0px' },
    );
}

function destroy(el: HTMLElement) {
    const slider = map.get(el);

    if (slider) {
        slider.destroy();
        map.delete(el);
    }
}

const _module = { init, destroy };

export default _module;
