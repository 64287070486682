import barba from '@barba/core';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const popupName = 'menu';
const menuPopup = document.querySelector<LitPopupElement>(`app-lit-popup[data-lit-popup="${popupName}"]`);
let openTimer: NodeJS.Timeout;
let closeTimer: NodeJS.Timeout;

function onOpen() {
    clearTimeout(openTimer);
    document.body.classList.add('menu-opened');

    openTimer = setTimeout(() => {
        Array.from(document.querySelectorAll<HTMLElement>('.js-menu-open-btn')).forEach((el) => {
            el.classList.add('is-opened');
            el.removeAttribute('data-lit-popup-open');
            el.setAttribute('data-lit-popup-close', popupName);
        });
    }, 1);
}

function onClose() {
    clearTimeout(closeTimer);
    document.body.classList.remove('menu-opened');

    closeTimer = setTimeout(() => {
        Array.from(document.querySelectorAll<HTMLElement>('.js-menu-open-btn')).forEach((el) => {
            el.removeAttribute('data-lit-popup-close');
            el.setAttribute('data-lit-popup-open', popupName);
            el.classList.remove('is-opened', 'focus-visible');
        });
    }, 1);
}

function init() {
    if (menuPopup) {
        const buyButtonsContainer = menuPopup.querySelector('.js-menu-buy-buttons');
        const socContainer = menuPopup.querySelector('.js-menu-soc');
        const headerSoc = document.querySelector('.js-header-soc');
        const buyButtons = Array.from(document.querySelectorAll('.js-header-buy-btn'));

        if (buyButtonsContainer && buyButtons.length > 0) {
            buyButtons.forEach((el) => {
                const clone = el.cloneNode(true) as HTMLElement;
                clone.classList.add('header-btn--clone');
                buyButtonsContainer.appendChild(clone);
            });
        }

        if (socContainer && headerSoc) {
            const clone = headerSoc.cloneNode(true) as HTMLElement;
            clone.classList.add('header-soc--clone');
            socContainer.appendChild(clone);
        }

        menuPopup.instance.on('open', onOpen);
        menuPopup.instance.on('close', onClose);

        barba.hooks.enter(() => {
            menuPopup.close();
        });
    }
}

function destroy() {
    clearTimeout(closeTimer);
    if (menuPopup) {
        menuPopup.instance.off('open', onOpen);
        menuPopup.instance.off('close', onClose);
    }
}

const _module = { init, destroy };

export default _module;
