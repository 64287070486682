import delegate from 'delegate';

const ACTIVE_CLASS = 'is-active';

const map = new WeakMap<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-filter')).forEach((el) => {
        const buttons = Array.from(el.querySelectorAll<HTMLElement>('.js-filter-btn'));
        const blocks = Array.from(el.querySelectorAll<HTMLElement>('.js-filter-content'));

        const delegation = delegate(el, '.js-filter-btn', 'click', (event: any) => {
            const target = event.delegateTarget as HTMLElement;

            buttons.forEach((btn) => btn.classList.remove(ACTIVE_CLASS));
            target.classList.add(ACTIVE_CLASS);

            if (target.dataset.filterValue === '') {
                blocks.forEach((block) => {
                    block.hidden = false;
                });
            } else {
                const exactBlock = blocks.find((block) => block.id === target.dataset.filterValue);

                blocks.forEach((block) => {
                    block.hidden = true;
                });

                if (exactBlock) {
                    exactBlock.hidden = false;
                }
            }
        });
        map.set(el, delegation);
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-filter')).forEach((el) => {
        const delegation = map.get(el);

        if (delegation) {
            delegation.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
