import { LitElement, css, html, unsafeCSS } from 'lit';
import MarqueeConstructor from '../../components/marquee';
import _styles from './MarqueeText.ce.scss';

export interface MarqueeText {
    speed: number;
    _marquee: MarqueeConstructor | undefined;
}

export class MarqueeText extends LitElement {
    constructor() {
        super();
        this.speed = 1.5;
    }

    static get properties() {
        return {
            speed: {
                type: Number,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        setTimeout(() => {
            const container = this.renderRoot.querySelector<HTMLElement>('.container');
            if (this.classList.contains('marquee-without-dash')) {
                container?.classList.add('text-without-dash');
            }

            if (container) {
                this._marquee = new MarqueeConstructor(container, { speed: this.speed });
            }
        }, 0);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this._marquee?.destroy();
    }

    render() {
        return html`
            <div class="container">
                <div class="text">${this.textContent}</div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'x-marquee': MarqueeText;
    }
}
