import delegate from 'delegate';

/**
 * Очищаем поля скрытого поля при закрытии модального окна с формой
 */
function onPopupCloseComplete(this: HTMLElement) {
    const input = this.querySelector<HTMLInputElement>('input.js-target-email');

    if (input) {
        input.value = '';
    }
}

function onDataTargetEmailClick(event: any) {
    const target = event.delegateTarget as HTMLElement;
    const emails = target.dataset.targetEmail;
    const popupName = target.dataset.litPopupOpen;

    if (emails && popupName) {
        Array.from(document.querySelectorAll(`[data-lit-popup="${popupName}"]`)).forEach((popupEl) => {
            const input = popupEl.querySelector<HTMLInputElement>('input.js-target-email');

            if (input) {
                input.value = emails;
            }

            popupEl.addEventListener('close-complete', onPopupCloseComplete);
        });
    }
}

let delegation: any;

function init(container: HTMLElement | Document = document) {
    delegation = delegate(container, '[data-target-email]', 'click', onDataTargetEmailClick);
}

function destroy(container: HTMLElement | Document = document) {
    if (delegation) {
        delegation.destroy();
        delegation = null;
    }

    Array.from(document.querySelectorAll('[data-lit-popup]')).forEach((popupEl) => {
        popupEl.removeEventListener('close-complete', onPopupCloseComplete);
    });
}

const _module = { init, destroy };

export default _module;
