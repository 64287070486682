import delegate from 'delegate';

function init() {
    delegate(document, '.js-toggle-password-visibility', 'click', (event: any) => {
        const target = event.delegateTarget as HTMLElement;
        const input = target.parentElement?.querySelector('input');

        if (input) {
            input.type = input.type === 'password' ? 'text' : 'password';

            if (input.type === 'text') {
                target.classList.add('input-group__icon--toggle-password--visible');
            } else {
                target.classList.remove('input-group__icon--toggle-password--visible');
            }
        }
    });
}

const _module = { init };

export default _module;
