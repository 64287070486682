import { fetchSwiper } from '../dynamic-modules';
import { map } from '../components/base-sliders';
import { Collapse } from '../custom-elements/Collapse/Collapse';
import Swiper from 'swiper';
let slider: Swiper;

function handleClick(index: number, collapses: NodeListOf<Collapse>) {
    if (slider) {
        slider.slideToLoop(index);
    }

    collapses.forEach((collapse, i) => {
        if (i !== index) {
            collapse.collapsed = true;
        }
    });
}

const eventListeners = new Map<Element, { index: number; listener: EventListener }>();

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-deal-program-slider')).forEach(async (el) => {
        const { Swiper, EffectFade } = await fetchSwiper();

        slider = new Swiper(el, {
            modules: [EffectFade],
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            loop: true,
            allowTouchMove: false,
        });
        map.set(el, slider);
    });

    const collapses = container.querySelectorAll<Collapse>('.js-deal-collapse');

    collapses.forEach((el, index) => {
        const listener = () => handleClick(index, collapses);
        el.addEventListener('click', listener);
        eventListeners.set(el, { index, listener });
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-announcement-swiper')).forEach((el) => {
        const slider = map.get(el);
        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });

    const collapses = container.querySelectorAll<Collapse>('.js-deal-collapse');

    collapses.forEach((el) => {
        const eventListener = eventListeners.get(el);
        if (eventListener) {
            el.removeEventListener('click', eventListener.listener);
            eventListeners.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
