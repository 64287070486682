import type SlimSelectConstructor from 'slim-select';
import { computePosition, flip, offset, shift } from '@floating-ui/dom';
import { isMobileDevice } from '../utils/mobile';

const map = new Map<HTMLSelectElement, SlimSelectConstructor>();

async function init(container: Element | Document = document) {
    if (!isMobileDevice()) {
        const elements = Array.from<HTMLSelectElement>(
            container.querySelectorAll('select.slim-select:not(.slim-select-container--initialized)'),
        );

        if (elements.length > 0) {
            const SlimSelect = await import('slim-select' /* webpackChunkName: "slim-select" */).then((m) => m.default);

            elements.forEach((el) => {
                const container = el.closest<HTMLElement>('.slim-select-container');
                const select = new SlimSelect({
                    select: el,
                    allowDeselect: el.classList.contains('slim-select--allow-deselect'),
                    hideSelectedOption: true,
                    showSearch: el.classList.contains('slim-select--search'),
                    searchPlaceholder: 'Поиск',
                    searchText: 'Ничего не найдено',
                    // settings: {
                    //     allowDeselect: el.classList.contains('slim-select--allow-deselect'),
                    //     hideSelected: true,
                    //     showSearch: false,
                    // },
                    beforeOpen: () => {
                        container?.classList.add('is-open');
                    },
                    afterClose: () => {
                        container?.classList.remove('is-open');
                    },
                    // events: {
                    //     afterChange: () => {
                    //         // Slim-select bug: не триггерится нативное событие "change"
                    //         el.dispatchEvent(new Event('change'));
                    //     },
                    //     afterOpen: () => {
                    //         const contentEl = document.querySelector<HTMLElement>(`[data-id="${el.id}"]`);

                    //         if (contentEl) {
                    //             Object.assign(contentEl.style, {
                    //                 left: `${0}px`,
                    //                 top: `${0}px`,
                    //             });
                    //             // computePosition(select.settings.contentLocation, contentEl, {
                    //             //     placement: 'left',
                    //             //     middleware: [flip() /*  offset(20), shift({ padding: 8 }) */],
                    //             // }).then(({ x, y }) => {
                    //             //     Object.assign(contentEl.style, {
                    //             //         left: `${x}px`,
                    //             //         top: `${y}px`,
                    //             //     });
                    //             // });
                    //         }
                    //     },
                    // },
                });
                const parent = el.parentElement;
                map.set(el, select);
                if (parent?.classList.contains('slim-select-container')) {
                    parent.classList.add('slim-select-container--initialized');
                }
            });
        }
    }
}

function destroy(container: Element | Document = document) {
    const elements = Array.from<HTMLSelectElement>(
        container.querySelectorAll('select.slim-select.slim-select-container--initialized'),
    );

    elements.forEach((el) => {
        const select = map.get(el);

        if (select) {
            const parent = el.parentElement;
            select.destroy();
            map.delete(el);

            if (parent?.classList.contains('slim-select-container')) {
                parent.classList.remove('slim-select-container--initialized');
            }
        }
    });
}

function getInstanceByElement(el?: HTMLSelectElement | null) {
    return el ? map.get(el) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
