export const fetchSwiper = () => import(/* webpackChunkName: "swiper" */ 'swiper');

export const fetchAjaxFormSender = () =>
    import(
        '../modules/ajax-form-sender'
        /* webpackChunkName: "ajax-form-sender" */
    );

export const fetchValidator = () =>
    import(
        '../modules/validator'
        /* webpackChunkName: "validator" */
    );

export const fetchTinyColor = () =>
    import(
        'tinycolor2'
        /* webpackChunkName: "tinycolor2" */
    );

export const fetchAirDatepicker = () =>
    import(
        'air-datepicker'
        /* webpackChunkName: "air-datepicker" */
    );

export const fetchIMask = async () => import('imask' /* webpackChunkName: "imask" */).then((m) => m.default);

export const fetchIntlTelInput = async () => import('intl-tel-input' /* webpackChunkName: "intl-tel-input" */);

export const fetchContentList = () =>
    import(/* webpackChunkName: "content-list" */ '@chipsadesign/content-list').then((m) => m.default);

export const fetchContentListFilter = () =>
    import(
        /* webpackChunkName: "content-list-filter" */ '@chipsadesign/content-list/dist/modules/ContentListFilter'
    ).then((m) => m.default);

export const fetchLightgallery = () =>
    Promise.all([
        import(
            'lightgallery'
            /* webpackChunkName: "lightgallery" */
        ),
        // import(
        //     'lightgallery/css/lightgallery.css'
        //     /* webpackChunkName: "lightgallery-styles" */
        // ),
        // import(
        //     '../css/layout/_lightgallery.scss'
        //     /* webpackChunkName: "lightgallery-app-styles" */
        // ),
    ]).then(([m]) => m);

export const fetchMapbox = () => import(/* webpackChunkName: "mapbox-gl" */ '!mapbox-gl').then((m) => m.default);
