function init() {
    const container = document.querySelector('.js-menu-header-dropdown');
    const headerDropdown = document.querySelector('.js-header-dropdown');

    if (container && headerDropdown) {
        const clone = headerDropdown.cloneNode(true) as HTMLElement;
        clone.classList.add('header-dropdown--clone');
        container.appendChild(clone);
    }
}

const _module = { init };

export default _module;
