import delegate from 'delegate';
import barba from '@barba/core';

let delegation: any;
let changePageDelegation: any;

function dropdownToggle(e: Event) {
    const target = e.target as HTMLElement;
    const drops = Array.from(document.querySelectorAll('.header .js-dropdown'));
    const dropdown = target.closest<HTMLElement>('.js-dropdown');

    // if (drop) {
    if (dropdown) {
        dropdown.classList.toggle('is-show');
    } else {
        // dropdown.classList.remove('is-show');
        drops.forEach((el) => {
            el.classList.remove('is-show');
        });
    }
    // }
}

function onClick(event: any) {
    const target = event.delegateTarget as HTMLElement;
    const index = Array.from(target.parentElement!.querySelectorAll('.js-dropdown-link')).indexOf(target);
    const dropdownEls = Array.from(document.querySelectorAll<HTMLElement>('.js-dropdown'));

    dropdownEls.forEach((dropdownEl) => {
        const dropdownTitle = dropdownEl.querySelector('.js-dropdown-title');
        const links = Array.from(dropdownEl.querySelectorAll('.js-dropdown-link'));

        links.forEach((link) => {
            link.classList.remove('dropdown-link--active');
        });

        if (index > -1) {
            links[index].classList.add('dropdown-link--active');
        }

        if (dropdownTitle) {
            dropdownTitle.textContent = target.querySelector('.btn__text')?.textContent || '';
        }

        dropdownEl?.classList.remove('is-show');
    });
}

function setActiveLinkByIndex(index: number) {
    const dropdownEls = Array.from(document.querySelectorAll<HTMLElement>('.js-dropdown'));

    dropdownEls.forEach((dropdownEl) => {
        const dropdownTitle = dropdownEl.querySelector('.js-dropdown-title');
        const links = Array.from(dropdownEl.querySelectorAll('.js-dropdown-link'));

        links.forEach((link) => {
            link.classList.remove('dropdown-link--active');
        });

        if (index > -1) {
            links[index].classList.add('dropdown-link--active');

            if (dropdownTitle) {
                dropdownTitle.textContent = links[index].querySelector('.btn__text')?.textContent || '';
            }
        }
    });
}

function onChangePage(event: any) {
    const target = event.delegateTarget as HTMLLinkElement;
    const allLinks = document
        .querySelector('.js-header-dropdown')
        ?.querySelectorAll<HTMLLinkElement>('.js-dropdown-link');

    let index = -1;

    allLinks?.forEach((link, i) => {
        if (link.href === target.href) {
            index = i;
        }
    });

    setActiveLinkByIndex(index);
}

function check() {
    const json = document.querySelector('.js-header-links-json-data');

    if (json?.textContent) {
        const data = JSON.parse(json.textContent.trim());
        const dropdownEl = document.querySelector<HTMLElement>('.js-header-dropdown');

        if (dropdownEl) {
            const links = Array.from(dropdownEl.querySelectorAll<HTMLAnchorElement>('a.js-dropdown-link'));
            const regex = new RegExp(`^\/${data.prefix}$`);
            const index = links.findIndex(
                (link) => link.getAttribute('href') === `/${data.prefix}` || regex.test(link.href),
            );
            setActiveLinkByIndex(index);
        }
    }
}

function init() {
    window.addEventListener('click', dropdownToggle);
    delegation = delegate(document, '.js-dropdown-link', 'click', onClick);
    changePageDelegation = delegate(document, '.js-change-page-link', 'click', onChangePage);
    barba.hooks.afterEnter(() => {
        setTimeout(check, 1);
    });
}

function destroy() {
    window.removeEventListener('click', dropdownToggle);

    if (delegation) {
        delegation.destroy();
        delegation = null;
    }

    if (changePageDelegation) {
        changePageDelegation.destroy();
        changePageDelegation = null;
    }
}

const _module = { init, destroy };

export default _module;
