const ACTIVE_CLASS = 'order-card-more-info--opened';

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll('.js-order-card')).forEach((el) => {
        const popupEl = el.querySelector('.js-order-card-more-info');
        const openEls = Array.from(el.querySelectorAll('.js-order-card-more-info-open'));
        const closeEls = Array.from(el.querySelectorAll('.js-order-card-more-info-close'));

        const open = () => {
            document.body.classList.add('no-scroll');
            popupEl?.classList.add(ACTIVE_CLASS);
        };

        const close = () => {
            popupEl?.classList.remove(ACTIVE_CLASS);
            document.body.classList.remove('no-scroll');
        };

        openEls.forEach((el) => {
            el.addEventListener('click', open);
        });

        closeEls.forEach((el) => {
            el.addEventListener('click', close);
        });
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll('.js-order-card')).forEach((el) => {
        //
    });
}

const _module = { init, destroy };

export default _module;
