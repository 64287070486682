function onMouseenter(this: HTMLElement) {
    const content = this.closest('.js-tooltip')?.querySelector('.js-tooltip-content');
    content?.classList.add('is-visible');
}

function onMouseleave(this: HTMLElement) {
    const content = this.closest('.js-tooltip')?.querySelector('.js-tooltip-content');
    content?.classList.remove('is-visible');
}

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-tooltip-trigger')).forEach((el) => {
        el.addEventListener('mouseenter', onMouseenter);
        el.addEventListener('mouseleave', onMouseleave);
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-tooltip-trigger')).forEach((el) => {
        el.removeEventListener('mouseenter', onMouseenter);
        el.removeEventListener('mouseleave', onMouseleave);
    });
}

const _module = { init, destroy };

export default _module;
