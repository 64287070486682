import { fetchSwiper } from '../dynamic-modules';
import { createNav, map } from '../components/base-sliders';

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-accomodation-place-slider')).forEach(async (el) => {
        const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

        if (slides.length > 1) {
            const { Swiper } = await fetchSwiper();
            const nav = el.querySelector('.js-slider-nav');

            const slider = new Swiper(el, {
                slidesPerView: 'auto',
                speed: 500,
                loop: true,
                on: {
                    init: (swiper) => {
                        createNav(
                            swiper,
                            nav,
                            'accomodation-slider-nav-btn',
                            `<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 1L1 5L5 9" stroke="white"/>
                            </svg>
                            `,
                        );
                    },
                },
            });
            map.set(el, slider);
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-accomodation-place-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
