import barba from '@barba/core';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const popupName = 'account-menu';
let menuPopup: LitPopupElement | null;
let openTimer: NodeJS.Timeout;
let closeTimer: NodeJS.Timeout;

function onOpen() {
    clearTimeout(openTimer);
    document.body.classList.add('menu-opened');

    openTimer = setTimeout(() => {
        Array.from(document.querySelectorAll<HTMLElement>('.js-account-menu-open-btn')).forEach((el) => {
            el.classList.add('is-opened');
            el.removeAttribute('data-lit-popup-open');
            el.setAttribute('data-lit-popup-close', popupName);
        });
    }, 1);
}

function onClose() {
    clearTimeout(closeTimer);
    document.body.classList.remove('menu-opened');

    closeTimer = setTimeout(() => {
        Array.from(document.querySelectorAll<HTMLElement>('.js-account-menu-open-btn')).forEach((el) => {
            el.removeAttribute('data-lit-popup-close');
            el.setAttribute('data-lit-popup-open', popupName);
            el.classList.remove('is-opened', 'focus-visible');
        });
    }, 1);
}

function init(container: HTMLElement | Document = document) {
    menuPopup = container.querySelector<LitPopupElement>(`app-lit-popup[data-lit-popup="${popupName}"]`);

    if (menuPopup) {
        // menuPopup.instance.destroy();
        // menuPopup.instance.constructor(popupName    );
        menuPopup.disconnectedCallback?.();
        menuPopup.connectedCallback?.();
        menuPopup.instance?.on('open', onOpen);
        menuPopup.instance?.on('close', onClose);

        barba.hooks.enter(() => {
            menuPopup?.close();
        });
    }
}

function destroy() {
    clearTimeout(closeTimer);
    if (menuPopup) {
        menuPopup.instance?.off('open', onOpen);
        menuPopup.instance?.off('close', onClose);
        menuPopup = null;
    }
}

const _module = { init, destroy };

export default _module;
