const selector =
    '[data-reveal], body:not(.account-page) .wysiwyg > *:not(b):not(i), [data-barba-namespace="text-page"] > *';

const observer = new IntersectionObserver((entries, obs) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            obs.unobserve(entry.target);
            entry.target.addEventListener(
                'transitionend',
                () => {
                    entry.target.classList.add('is-reveal-complete');
                },
                { once: true },
            );
            entry.target.classList.add('is-revealed');
        }
    });
});

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
    elements.forEach((el) => {
        observer.observe(el);
    });
}

function veil(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
    elements.forEach((el) => {
        observer.unobserve(el);
        el.classList.remove('is-revealed');
        el.classList.remove('is-reveal-complete');
    });
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
    elements.forEach((el) => {
        observer.unobserve(el);
    });
}

function reinit(container: Element | Document = document) {
    destroy(container);

    setTimeout(() => {
        const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
        elements.forEach((el) => {
            el.classList.remove('is-revealed');
            el.classList.remove('is-reveal-complete');
        });
    }, 1);

    setTimeout(() => {
        init(container);
    }, 2);
}

const _module = { init, veil, destroy, reinit };

export default _module;
