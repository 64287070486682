import { fetchAirDatepicker } from '../dynamic-modules';
import { setButtonLoading } from '../utils/loading-btn';
import { calculateAirDatepickerPosition, triggerFloatedLabelsUpdate } from './datepicker';

/**
 * Создает дату из строки "dd.mm.yyyy"
 *
 * @param dateString строка "dd.mm.yyyy"
 * @returns Date
 */
function createDateFromString(dateString: string): Date {
    const parts = dateString.split('.');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
}

/**
 * Создает строку в формате "dd.mm.yyyy" из объекта даты
 *
 * @param dateString строка "dd.mm.yyyy"
 * @returns {String}
 */
function createStringFromDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
}

/**
 * Возращает ближайшую по времени дату
 *
 * @param dates массив дат
 * @returns Date
 */
const getEarliestDate = (...dates: Date[]) => dates.sort((a, b) => a.getTime() - b.getTime())[0];

/**
 * Возращает самую дальнюю по времени дату
 *
 * @param dates массив дат
 * @returns Date
 */
const getLatestDate = (...dates: Date[]) => dates.sort((a, b) => b.getTime() - a.getTime())[0];

const map = new Map<HTMLInputElement, any>();

function init(container: HTMLElement | Document = document) {
    const forms = Array.from(container.querySelectorAll('.js-accomodation-place-rooms-form'));

    forms.forEach((form) => {
        const datepickerFrom = form.querySelector<HTMLInputElement>('input.js-datepicker-order-living-from');
        const datepickerTo = form.querySelector<HTMLInputElement>('input.js-datepicker-order-living-to');

        form.addEventListener('submit', () => {
            const submitBtn = form.querySelector<HTMLButtonElement>('button.js-accomodation-place-rooms-submit-btn');

            if (submitBtn) {
                setButtonLoading(submitBtn);
            }
        });

        if (datepickerFrom && datepickerTo) {
            fetchAirDatepicker().then(({ default: AirDatepicker }) => {
                const today = new Date();

                const possibleFromMinDate = datepickerFrom.dataset.min
                    ? createDateFromString(datepickerFrom.dataset.min)
                    : new Date();
                const possibleToMinDate = datepickerTo.dataset.min
                    ? createDateFromString(datepickerTo.dataset.min)
                    : new Date();

                const possibleFromMaxDate = datepickerFrom.dataset.max
                    ? createDateFromString(datepickerFrom.dataset.max)
                    : undefined;
                const possibleToMaxDate = datepickerTo.dataset.max
                    ? createDateFromString(datepickerTo.dataset.max)
                    : undefined;

                const bookedFromDates = datepickerFrom.dataset.booked
                    ? (JSON.parse(datepickerFrom.dataset.booked) as string[])
                    : [];
                const bookedToDates = datepickerTo.dataset.booked
                    ? (JSON.parse(datepickerTo.dataset.booked) as string[])
                    : [];

                const datepickerFromInstance = new AirDatepicker(datepickerFrom, {
                    container: datepickerFrom.closest<HTMLElement>('.input-group')!,
                    minDate: possibleFromMinDate.getTime() > today.getTime() ? possibleFromMinDate : today,
                    maxDate: possibleFromMaxDate,
                    position: calculateAirDatepickerPosition,
                    onRenderCell: ({ date, cellType }) => {
                        if (cellType === 'day') {
                            if (bookedFromDates.includes(createStringFromDate(date))) {
                                return { disabled: true };
                            }
                        }
                    },
                    onSelect: ({ date }) => {
                        if (date instanceof Date) {
                            const prevDateTo = createDateFromString(datepickerTo.value);
                            const minDate = getLatestDate(date, possibleFromMinDate);
                            datepickerToInstance.update({ minDate });
                            datepickerTo.value = createStringFromDate(getLatestDate(minDate, prevDateTo));
                            triggerFloatedLabelsUpdate(datepickerTo);
                        }
                    },
                });
                datepickerFrom.readOnly = true;
                datepickerFrom.autocomplete = 'off';
                map.set(datepickerFrom, datepickerFromInstance);

                const datepickerToInstance = new AirDatepicker(datepickerTo, {
                    container: datepickerTo.closest<HTMLElement>('.input-group')!,
                    minDate: possibleToMinDate.getTime() > today.getTime() ? possibleToMinDate : today,
                    maxDate: possibleToMaxDate,
                    position: calculateAirDatepickerPosition,
                    onRenderCell: ({ date, cellType }) => {
                        if (cellType === 'day') {
                            if (bookedToDates.includes(createStringFromDate(date))) {
                                return { disabled: true };
                            }
                        }
                    },
                    onSelect: ({ date }) => {
                        if (date instanceof Date) {
                            const prevDateFrom = createDateFromString(datepickerFrom.value);
                            const maxDate = possibleToMaxDate ? getEarliestDate(date, possibleToMaxDate) : date;
                            datepickerFromInstance.update({ maxDate });
                            datepickerFrom.value = createStringFromDate(getEarliestDate(maxDate, prevDateFrom));
                            triggerFloatedLabelsUpdate(datepickerFrom);
                        }
                    },
                });
                datepickerTo.readOnly = true;
                datepickerTo.autocomplete = 'off';
                map.set(datepickerTo, datepickerToInstance);
            });
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    //
}

function getInstanceByElement(el?: HTMLInputElement | null) {
    return el ? map.get(el) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
