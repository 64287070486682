import { IdleQueue } from 'idlize/IdleQueue.mjs';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ease1 } from '../utils/easings';
import { fetchSwiper } from '../dynamic-modules';
import { createNav, destroyNav, map } from '../components/base-sliders';
import LazyLoad from 'vanilla-lazyload';
// import { executeOnIntersection } from '../utils/execute-on-intersection';
const beforeEnterQueue = new IdleQueue();

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-news-slider:not(.swiper-initialized)')).forEach(
        async (el) => {
            const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

            if (matchMedia('(max-width: 767px), (max-width: 815px) and (orientation: landscape)').matches) {
                const MAX_LENGTH = 400;

                slides.forEach((slide) => {
                    const description = slide.querySelector<HTMLElement>('.js-news-card-description');

                    if (description) {
                        const text = description.textContent?.trim();

                        if (text) {
                            if (text.length < MAX_LENGTH) {
                                return;
                            }

                            let newText = text.slice(0, MAX_LENGTH);

                            if (newText[newText.length - 1] === ' ') {
                                // 400-й символ - пробел
                                newText += '...';
                            } else {
                                let i = 0;

                                // Ищем крайний пробел
                                while (newText[newText.length - i] !== ' ') {
                                    i++;
                                }

                                newText = newText.slice(0, MAX_LENGTH - i);
                                newText += '...';
                            }

                            description.textContent = newText.trim();
                        }
                    }
                });
            }

            if (slides.length > 1) {
                const { Swiper, Lazy, Autoplay } = await fetchSwiper();
                const navs = Array.from(el.querySelectorAll<HTMLElement>('.js-slider-nav'));
                const titlesTweensMap = new Map<HTMLElement, gsap.core.Tween>();
                const descriptionsTweensMap = new Map<HTMLElement, gsap.core.Tween>();
                let timer: NodeJS.Timeout;
                const autoplayDuration = 7000;

                function playSlide(slide: Element, action: 'enter' | 'leave') {
                    if (matchMedia('(max-width: 767px), (max-width: 815px) and (orientation: landscape)').matches) {
                        return;
                    }

                    if (action === 'leave') {
                        slides
                            .find((slide) => slide.classList.contains('swiper-slide-was-active'))
                            ?.classList.remove('swiper-slide-was-active');
                        slide.classList.add('swiper-slide-was-active');
                    }

                    const title = slide.querySelector<HTMLElement>('.js-news-card-title');
                    const description = slide.querySelector<HTMLElement>('.js-news-card-description');

                    if (title) {
                        const titleTween = titlesTweensMap.get(title);

                        if (titleTween) {
                            if (action === 'enter') {
                                titleTween.restart();
                            } else {
                                titleTween.reverse();
                            }
                        }
                    }

                    if (description) {
                        const descriptionTween = descriptionsTweensMap.get(description);

                        if (descriptionTween) {
                            if (action === 'enter') {
                                descriptionTween.restart();
                            } else {
                                descriptionTween.reverse();
                            }
                        }
                    }
                }

                beforeEnterQueue.pushTask(() => {
                    const slider = new Swiper(el, {
                        modules: [Lazy, Autoplay],
                        slidesPerView: 1,
                        watchSlidesProgress: true,
                        virtualTranslate: true,
                        speed: 0,
                        // preloadImages: false,
                        // lazy: {
                        //     loadPrevNext: true,
                        //     checkInView: true,
                        // },
                        rewind: true,
                        autoplay: {
                            delay: autoplayDuration,
                            disableOnInteraction: false,
                            waitForTransition: false,
                        },
                        on: {
                            beforeInit: (swiper) => {
                                navs.forEach((nav) => {
                                    createNav(swiper, nav);
                                });
                            },
                            init: (swiper) => {
                                el.style.setProperty('--autoplay-duration', `${autoplayDuration}ms`);

                                if (
                                    !matchMedia('(max-width: 767px), (max-width: 815px) and (orientation: landscape)')
                                        .matches
                                ) {
                                    const titles = swiper.slides.map((el) =>
                                        el.querySelector<HTMLElement>('.js-news-card-title'),
                                    );
                                    const descriptions = swiper.slides.map((el) =>
                                        el.querySelector<HTMLElement>('.js-news-card-description'),
                                    );

                                    titles.forEach((_el) => {
                                        if (_el) {
                                            const splitted = new SplitText(_el, { type: 'lines' });
                                            const tween = gsap.fromTo(
                                                splitted.lines,
                                                { opacity: 0, yPercent: 100 },
                                                {
                                                    yPercent: 0,
                                                    opacity: 1,
                                                    duration: 0.5,
                                                    stagger: 0.05,
                                                    ease: ease1,
                                                    paused: true,
                                                },
                                            );
                                            titlesTweensMap.set(_el, tween);
                                        }
                                    });
                                    descriptions.forEach((_el) => {
                                        if (_el) {
                                            const splitted = new SplitText(_el, { type: 'lines' });
                                            const tween = gsap.fromTo(
                                                splitted.lines,
                                                { opacity: 0, yPercent: 100 },
                                                {
                                                    yPercent: 0,
                                                    opacity: 1,
                                                    duration: 0.5,
                                                    stagger: 0.05,
                                                    ease: ease1,
                                                    paused: true,
                                                },
                                            );
                                            descriptionsTweensMap.set(_el, tween);
                                        }
                                    });
                                }
                            },
                            afterInit: (swiper) => {
                                requestAnimationFrame(() => {
                                    playSlide(slides[swiper.activeIndex], 'enter');
                                });
                                const img = slides[swiper.activeIndex].querySelector('img');
                                const imgNext = slides[swiper.activeIndex + 1] ? slides[swiper.activeIndex + 1].querySelector('img') : null;
                                if (img && !img.classList.contains('loaded')) LazyLoad.load(img, {});
                                if (imgNext && !imgNext.classList.contains('loaded')) LazyLoad.load(imgNext, {});

                            },
                            slideChange: (swiper) => {
                                clearTimeout(timer);
                                requestAnimationFrame(() => {
                                    playSlide(slides[swiper.previousIndex], 'leave');

                                    timer = setTimeout(() => {
                                        playSlide(slides[swiper.activeIndex], 'enter');
                                    }, 300);
                                });
                                const img = slides[swiper.activeIndex].querySelector('img');
                                const imgNext = slides[swiper.activeIndex + 1] ? slides[swiper.activeIndex + 1].querySelector('img') : null;
                                if (img && !img.classList.contains('loaded')) LazyLoad.load(img, {});
                                if (imgNext && !imgNext.classList.contains('loaded')) LazyLoad.load(imgNext, {});
                            },
                            destroy: () => {
                                destroyNav(el);
                            },
                        },
                    });
                    map.set(el, slider);
                    // slider.disable();

                    // executeOnIntersection(
                    //     el,
                    //     () => {
                    //         slider.enable();
                    //     },
                    //     { rootMargin: '200px 0px 200px 0px' },
                    // );
                });
            }
        },
    );
}

function destroy(container: HTMLElement | Document = document) {
    beforeEnterQueue.clearPendingTasks();

    Array.from(container.querySelectorAll<HTMLElement>('.js-news-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
