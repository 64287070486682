const scrollTopButton = document.querySelector('.js-scrolltop-btn');

const observer = new IntersectionObserver(
    (entries) => {
        if (scrollTopButton) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    scrollTopButton.classList.add('is-visible');
                } else {
                    scrollTopButton.classList.remove('is-visible');
                }
            });
        }
    },
    { rootMargin: '300% 0% -300% 0%' },
);

function init() {
    observer.observe(document.body);
}

const _module = { init };

export default _module;
