import { IdleQueue } from 'idlize/IdleQueue.mjs';
import delegate from 'delegate';
import { fetchSwiper } from '../dynamic-modules';
import { map } from '../components/base-sliders';

const resizeMap = new WeakMap<Element, () => void>();
const delegateMap = new WeakMap<Element, { openDelegation: any; closeDelegation: any }>();
const beforeEnterQueue = new IdleQueue();

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-testimonials-slider')).forEach((el) => {
        beforeEnterQueue.pushTask(async () => {
            const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

            const openDelegation = delegate(el, '.js-testimonial-read-more-btn', 'click', (event: any) => {
                const target = event.delegateTarget as HTMLElement;
                const slider = map.get(el);

                if (slider) {
                    target.closest('.swiper-slide')?.classList.add('swiper-slide-popup-opened');
                    slider.disable();
                    el.classList.add('swiper-disabled');
                }
            });

            const closeDelegation = delegate(el, '.js-testimonial-popup-close-btn', 'click', (event: any) => {
                const target = event.delegateTarget as HTMLElement;
                const slider = map.get(el);

                if (slider) {
                    target.closest('.swiper-slide')?.classList.remove('swiper-slide-popup-opened');
                    slider.enable();
                    el.classList.remove('swiper-disabled');
                }
            });

            delegateMap.set(el, { openDelegation, closeDelegation });

            function onResize() {
                Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide')).forEach((slide) => {
                    const textEl = slide.querySelector('.js-testimonial-text');

                    if (textEl) {
                        if (textEl.scrollHeight > textEl.clientHeight) {
                            textEl.classList.add('is-truncated');
                        } else {
                            textEl.classList.remove('is-truncated');
                        }
                    }
                });
            }

            onResize();
            window.addEventListener('resize', onResize);
            resizeMap.set(el, onResize);

            if (slides.length > 3) {
                const { Swiper, Pagination, Lazy } = await fetchSwiper();

                const slider = new Swiper(el, {
                    modules: [Pagination, Lazy],
                    slidesPerView: 'auto',
                    speed: 1000,
                    loop: true,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true,
                        loadPrevNextAmount: 3,
                        checkInView: true,
                    },
                    pagination: {
                        el: '.js-pagination',
                        type: 'bullets',
                        clickable: true,
                        renderBullet: (_, className) =>
                            `<button class="testimonials-slider__pagination-btn ${className}"></button>`,
                    },
                });
                map.set(el, slider);
            }
        });
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-testimonials-slider')).forEach((el) => {
        const slider = map.get(el);
        const resizeFn = resizeMap.get(el);
        const delegateObj = delegateMap.get(el);

        if (resizeFn) {
            window.removeEventListener('resize', resizeFn);
            resizeMap.delete(el);
        }

        if (delegateObj) {
            delegateObj.openDelegation.destroy();
            delegateObj.closeDelegation.destroy();
            delegateMap.delete(el);
        }

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
