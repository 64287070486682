import { setButtonLoading } from '../utils/loading-btn';

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLButtonElement>('button.js-loading-submit-btn')).forEach((btn) => {
        const form = btn.closest('form');

        if (form) {
            form.addEventListener('submit', () => {
                if (btn) {
                    setButtonLoading(btn);
                }
            });
        }
    });
}

const _module = { init };

export default _module;
