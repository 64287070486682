import './sentry';
import './custom-elements';
import 'lite-youtube-embed';
import 'lite-youtube-embed/src/lite-yt-embed.css';
import './webpack-imports';
import './metrics';
import delegate from 'delegate';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import barba from '@barba/core';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
import { applyPolyfills } from './polyfills';
import { initCustomElements } from './custom-elements';
import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
import BaseTransition from './transitions/base';
import { initWorklets } from './inits/worklets';
import menu from './inits/menu';
import scrolltopBtn from './inits/scrolltop-btn';
import floatedLabels from './inits/floated-labels';
import inputMasks from './inits/masks';
import intlPhoneInputs from './inits/intl-phone-input';
import ajaxForms from './inits/ajax-forms';
import togglePassword from './inits/toggle-password';
import { createInitialBaseTimeline } from './animations/initial-base';
import { preloader } from './components/preloader';
import { timeout } from './utils/timeout';
import clones from './inits/clones';
import dropdown from './inits/dropdown';
import { LitPopupElement } from './custom-elements/LitPopupElement/LitPopupElement';
import vhMobileFix from '../modules/vh-mobile-fix';
import { isAppleDevice } from './utils/is-apple-device';

document.documentElement.classList.add('js-ready');

if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual';
}

applyPolyfills().then(() => {
    gsap.config({ nullTargetWarn: false });
    gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CSSRulePlugin);

    vhMobileFix();

    if (isAppleDevice) {
        document.documentElement.classList.add('apple-device');
    }

    const appQueue = new IdleQueue();
    const contactUsPopupEl = document.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="contact-us"]');
    const becomeSpeakerPopupEl = document.querySelector<LitPopupElement>(
        'app-lit-popup[data-lit-popup="become-speaker"]',
    );

    appQueue.pushTask(initCustomElements);
    appQueue.pushTask(initWorklets);
    appQueue.pushTask(() => scrolltopBtn.init());
    appQueue.pushTask(() => {
        if (document.body.classList.contains('account-page')) {
            clones.init();
        }
        menu.init();
    });
    appQueue.pushTask(() => {
        dropdown.init();
    });

    appQueue.pushTask(() => {
        if (contactUsPopupEl) {
            ajaxForms.init(contactUsPopupEl);
            floatedLabels.init(contactUsPopupEl);
            inputMasks.init(contactUsPopupEl);
            intlPhoneInputs.init(contactUsPopupEl);
        }
    });

    appQueue.pushTask(() => {
        if (becomeSpeakerPopupEl) {
            ajaxForms.init(becomeSpeakerPopupEl);
            floatedLabels.init(becomeSpeakerPopupEl);
            inputMasks.init(becomeSpeakerPopupEl);
            intlPhoneInputs.init(becomeSpeakerPopupEl);
        }
    });

    appQueue.pushTask(() => {
        togglePassword.init();
    });

    delegate(document, '.js-back-link', 'click', () => {
        window.history.back();
    });

    initBaseView();

    const initApp = () => {
        document.body.classList.remove('no-scroll');
        const animation = createInitialBaseTimeline();

        barba.init({
            transitions: [BaseTransition],
            views: [
                lazyView('index-page', () =>
                    import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('about-page', () =>
                    import(/* webpackChunkName: "about-page" */ './views/about').then((m) => m.default),
                ),
                lazyView('news-page', () =>
                    import(/* webpackChunkName: "news-page" */ './views/news').then((m) => m.default),
                ),
                lazyView('speakers-page', () =>
                    import(/* webpackChunkName: "speakers-page" */ './views/speakers').then((m) => m.default),
                ),
                lazyView('program-page', () =>
                    import(/* webpackChunkName: "program-page" */ './views/program').then((m) => m.default),
                ),
                lazyView('accomodation-page', () =>
                    import(/* webpackChunkName: "accomodation-page" */ './views/accomodation').then((m) => m.default),
                ),
                lazyView('participants-page', () =>
                    import(/* webpackChunkName: "participants-page" */ './views/participants').then((m) => m.default),
                ),
                lazyView('press-page', () =>
                    import(/* webpackChunkName: "press-page" */ './views/press').then((m) => m.default),
                ),
                lazyView('rieltory-page', () =>
                    import(/* webpackChunkName: "rieltory-page" */ './views/rieltory').then((m) => m.default),
                ),
                lazyView('rieltory-detail-page', () =>
                    import(/* webpackChunkName: "rieltory-detail-page" */ './views/rieltory-detail').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('events-calendar-page', () =>
                    import(/* webpackChunkName: "events-calendar-page" */ './views/events-calendar').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('account-login-page', () =>
                    import(/* webpackChunkName: "account-login-page" */ './views/account-login').then((m) => m.default),
                ),
                lazyView('account-forgot-page', () =>
                    import(/* webpackChunkName: "account-forgot-page" */ './views/account-forgot').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('account-renew-password-page', () =>
                    import(/* webpackChunkName: "account-renew-password-page" */ './views/account-renew-password').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('account-register-base-page', () =>
                    import(/* webpackChunkName: "account-register-base-page" */ './views/account-register-base').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('account-register-page', () =>
                    import(/* webpackChunkName: "account-register-page" */ './views/account-register').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('account-fill-order-data-page', () =>
                    import(
                        /* webpackChunkName: "account-fill-order-data-page" */ './views/account-fill-order-data'
                    ).then((m) => m.default),
                ),
                lazyView('account-orders-page', () =>
                    import(/* webpackChunkName: "account-orders-page" */ './views/account-orders').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('account-faq-page', () =>
                    import(/* webpackChunkName: "account-faq-page" */ './views/account-faq').then((m) => m.default),
                ),
                lazyView('account-buy-tickets-page', () =>
                    import(/* webpackChunkName: "account-buy-tickets-page" */ './views/account-buy-tickets').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('main-page', () =>
                    import(/* webpackChunkName: "main-page", webpackPrefetch: true */ './views/main').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('builders-page', () =>
                    import(/* webpackChunkName: "rieltory-detail-page" */ './views/builders').then((m) => m.default),
                ),
                lazyView('account-buy-accomodation-page', () =>
                    import(
                        /* webpackChunkName: "account-buy-accomodation-page" */ './views/account-buy-accomodation'
                    ).then((m) => m.default),
                ),
                lazyView('account-buy-accomodation-new-page', () =>
                    import(
                        /* webpackChunkName: "account-buy-accomodation-new-page" */ './views/account-buy-accomodation-new'
                    ).then((m) => m.default),
                ),
                lazyView('expo-page', () =>
                    import(/* webpackChunkName: "expo-page" */ './views/expo').then((m) => m.default),
                ),
            ],
            prefetchIgnore: true,
            preventRunning: true,
            prevent: ({ el }) =>
                (el.tagName === 'A' && !!el.closest('#bx-panel')) || document.body.classList.contains('account-page'),
            timeout: 5000,
            debug: NODE_ENV === 'development',
        });

        requestAnimationFrame(() => {
            animation.play();
        });
    };

    if (document.body.classList.contains('account-page')) {
        // Личный кабинет, убираем прелоадер
        initApp();
    } else {
        // Initial load
        preloader
            .loadAssets()
            .then(() => timeout(2000))
            .then(() => preloader.leave())
            .then(() => initApp());
    }

    function setVisibilityState() {
        if (document.visibilityState === 'hidden') {
            document.documentElement.classList.add('is-document-hidden');
        } else {
            document.documentElement.classList.remove('is-document-hidden');
        }
    }

    setVisibilityState();
    document.addEventListener('visibilitychange', setVisibilityState);
});
