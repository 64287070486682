import type { Collapse } from '../custom-elements/Collapse/Collapse';

const map = new WeakMap<HTMLElement, { expand: () => void; collapse: () => void; toggle: () => void }>();

function init(container: HTMLElement | Document = document) {
    const items = Array.from(container.querySelectorAll<Collapse>('app-collapse.js-collapsible-item'));

    if (matchMedia('(hover: none)').matches && items[0]) {
        items[0].collapsed = false;
    }

    items.forEach((el) => {
        const expand = () => {
            el.collapsed = false;
        };

        const collapse = () => {
            el.collapsed = true;
        };

        const toggle = () => {
            const wasCollapsed = el.collapsed;

            items.forEach((item) => {
                item.collapsed = true;
            });

            el.collapsed = !wasCollapsed;
        };

        if (matchMedia('(hover: none)').matches) {
            el.addEventListener('click', toggle);
        } else {
            el.addEventListener('mouseenter', expand);
            el.addEventListener('mouseleave', collapse);
        }

        map.set(el, { expand, collapse, toggle });
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<Collapse>('app-collapse.js-collapsible-item')).forEach((el) => {
        const obj = map.get(el);

        if (obj) {
            el.removeEventListener('click', obj.toggle);
            el.removeEventListener('mouseenter', obj.expand);
            el.removeEventListener('mouseleave', obj.collapse);
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
