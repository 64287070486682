// import gsap from 'gsap';
import barba from '@barba/core';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
import { IdleValue } from 'idlize/IdleValue.mjs';
import { lazyload } from '../inits/lazy-load';
import newsSliders from '../inits/news-sliders';
import aboutSliders from '../inits/about-sliders';
import testimonialsSliders from '../inits/testimonials-sliders';
import ajaxForms from '../inits/ajax-forms';
import headerTheme from '../inits/header-theme';
// import scrolltopBtnTheme from '../inits/scrollbtn-theme';
import floatedLabels from '../inits/floated-labels';
import inputMasks from '../inits/masks';
import revealAnimations from '../inits/reveal';
import videoPopups from '../inits/video-popups';
import galleries from '../inits/gallery';
import timelines from '../inits/timelines';
import collapsibleItemHover from '../inits/collapsible-item-hover';
import numbers from '../inits/numbers';
import tooltips from '../inits/tooltips';
import selects from '../inits/select';
import orderCards from '../inits/order-cards';
import filterContent from '../inits/filter-content';
import dropdowns from '../inits/dropdown-component';
import accountMenu from '../inits/account-menu';
import accountOrderHistoryFilter from '../inits/account-order-history-filter';
import { wrap } from '../utils/dom';
import { createInitialInnerPageTimeline } from '../animations/initial-inner-page';
// import { getCSSCustomProp } from '../utils/css';
import changeHeaderLinks from '../inits/change-header-links';
import intlPhoneInputs from '../inits/intl-phone-input';
import accomodationPlaceSliders from '../inits/account-accomodation-place-sliders';
import datepickers from '../inits/datepicker';
import accountOrderLivingDatepickers from '../inits/account-order-living-datepickers';
import accountFindAccomodationDatepickers from '../inits/account-find-accomodation-datepickers';
import headerActiveLinks from '../inits/header-active-links';
import addTargetEmailsToFormOnOpen from '../inits/add-target-emails-to-form-on-open';
import loadingSubmitBtn from '../inits/loading-submit-btn';
import dealProgramSlider from '../inits/deal-program-slider';
import developersKeyvisual from '../inits/developers-keyvisual';
import tableNav from '../inits/table-nav';

export function initBaseView() {
    const beforeEnterQueue = new IdleQueue();
    let baseEnterTl: gsap.core.Timeline | null;
    // let videoElContainer: HTMLElement | null | undefined;
    // let videoEl: HTMLElement | null | undefined;
    let scrollDownObserver: IntersectionObserver | null;

    // function setVideoElScale() {
    //     if (videoEl) {
    //         const offsetX = getCSSCustomProp(document.documentElement, '--offset-x', 'number') as number;
    //         videoEl.style.setProperty('--additional-scale', `${(offsetX * 2) / window.innerWidth}`);
    //     }
    // }

    // function onResize() {
    //     setVideoElScale();
    // }

    barba.hooks.beforeEnter((data) => {
        window.scrollTo({ top: 0, behavior: 'auto' });
        if (data) {
            // Адаптивные таблицы в wysiwyg
            Array.from(data.next.container.querySelectorAll('.wysiwyg table')).forEach((el) => {
                const wrapper = document.createElement('div');
                wrapper.className = 'table-responsive';
                wrap(el, wrapper);
            });

            const main = document.querySelector('.js-main');

            if (data.next.namespace === 'main-page') {
                main?.classList.add('main-index-page');
            } else {
                main?.classList.remove('main-index-page');
            }
        }

        baseEnterTl = new IdleValue(() => createInitialInnerPageTimeline(data?.next.container));

        beforeEnterQueue.pushTask(() => {
            if (data) {
                const autoPlayedVideos = Array.from(
                    data.next.container.querySelectorAll<HTMLVideoElement>('video[autoplay]'),
                );

                autoPlayedVideos.forEach((video) => {
                    video.play();
                });
            }
        });

        beforeEnterQueue.pushTask(() => {
            aboutSliders.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            dealProgramSlider.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            developersKeyvisual.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            newsSliders.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            testimonialsSliders.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            selects.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            timelines.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            numbers.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            floatedLabels.init();
        });

        beforeEnterQueue.pushTask(() => {
            tableNav.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            inputMasks.init();
        });

        beforeEnterQueue.pushTask(() => {
            const footer = document.querySelector<HTMLElement>('.js-footer');
            if (footer) {
                intlPhoneInputs.init(footer);
                inputMasks.init(footer);

                ajaxForms.init(footer);
                floatedLabels.init(footer);
            }
        });

        beforeEnterQueue.pushTask(() => {
            intlPhoneInputs.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            ajaxForms.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            galleries.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            collapsibleItemHover.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            tooltips.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            filterContent.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            orderCards.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            dropdowns.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            accountMenu.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            accountOrderHistoryFilter.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            accomodationPlaceSliders.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            datepickers.init(data?.next.container, 'input.js-datepicker-birthday', { maxDate: new Date() });
        });

        beforeEnterQueue.pushTask(() => {
            accountOrderLivingDatepickers.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            accountFindAccomodationDatepickers.init(data?.next.container);
        });

        // beforeEnterQueue.pushTask(() => {
        //     videoPopups.init(data?.next.container);
        // });

        beforeEnterQueue.pushTask(() => {
            changeHeaderLinks(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            headerActiveLinks(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            addTargetEmailsToFormOnOpen.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            loadingSubmitBtn.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            const secondSection = data?.next.container.querySelector<HTMLElement>('.js-second-section');
            const scrollDownEl = data?.next.container.querySelector('.js-scroll-down');

            if (secondSection) {
                scrollDownObserver = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (scrollDownEl) {
                                if (entry.isIntersecting) {
                                    scrollDownEl.classList.remove('is-visible');
                                } else {
                                    scrollDownEl.classList.add('is-visible');
                                }
                            }
                        });
                    },
                    { rootMargin: '0px 0px -100px 0px' },
                );

                scrollDownObserver.observe(secondSection);
            }
        });

        // beforeEnterQueue.pushTask(() => {
        //     videoElContainer = data?.next.container.querySelector<HTMLElement>('.js-story-video-trigger-container');
        //     videoEl = videoElContainer?.querySelector<HTMLElement>('.js-story-video-trigger');

        //     const observer = new IntersectionObserver(
        //         (entries) => {
        //             entries.forEach((entry) => {
        //                 if (videoEl) {
        //                     if (entry.isIntersecting) {
        //                         videoEl.classList.add('story-video--expanded');
        //                     } else {
        //                         videoEl.classList.remove('story-video--expanded');
        //                     }
        //                 }
        //             });
        //         },
        //         { threshold: 0.5 },
        //     );

        //     setVideoElScale();

        //     if (videoElContainer) {
        //         observer.observe(videoElContainer);
        //     }

        //     window.addEventListener('resize', onResize);
        // });
    });

    barba.hooks.enter((data) => {
        if (data && data.current && data.next && data.current.namespace && data.next.namespace) {
            document.body.classList.replace(data.current.namespace, data.next.namespace);
        }

        lazyload.update();
    });

    barba.hooks.afterEnter((data) => {
        beforeEnterQueue.pushTask(() => {
            headerTheme.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            videoPopups.init(data?.next.container);
        });

        // beforeEnterQueue.pushTask(() => {
        //     scrolltopBtnTheme.init();
        // });

        beforeEnterQueue.pushTask(() => {
            if (data) {
                revealAnimations.init(
                    data.next.namespace === 'text-page' ? data.next.container.parentElement! : data.next.container,
                );
            }
        });

        beforeEnterQueue.pushTask(() => {
            baseEnterTl?.getValue().play();
        });
    });

    barba.hooks.beforeLeave((data) => {
        beforeEnterQueue.clearPendingTasks();
        // window.removeEventListener('resize', onResize);
        videoPopups.destroy(data?.current.container);
        ajaxForms.destroy(data?.current.container);
        inputMasks.destroy(data?.current.container);
        revealAnimations.destroy(data?.current.container);
        floatedLabels.destroy(data?.current.container);
        galleries.destroy(data?.current.container);
        datepickers.destroy(data?.current.container, 'input.js-datepicker-birthday');

        if (scrollDownObserver) {
            scrollDownObserver.disconnect();
            scrollDownObserver = null;
        }

        collapsibleItemHover.destroy(data?.current.container);
        tooltips.destroy(data?.current.container);
        filterContent.destroy(data?.current.container);
        tableNav.destroy(data?.current.container);
        orderCards.destroy(data?.current.container);
        dropdowns.destroy(data?.current.container);
        accountMenu.destroy();
        accountOrderHistoryFilter.destroy();
        accountOrderLivingDatepickers.destroy(data?.current.container);
        accountFindAccomodationDatepickers.destroy(data?.current.container);
        addTargetEmailsToFormOnOpen.destroy(data?.current.container);
    });

    barba.hooks.afterLeave((data) => {
        headerTheme.destroy(data?.current.container);
        timelines.destroy(data?.current.container);
        numbers.destroy(data?.current.container);
        newsSliders.destroy(data?.current.container);
        dealProgramSlider.destroy(data?.current.container);
        developersKeyvisual.destroy(data?.current.container);
        aboutSliders.destroy(data?.current.container);
        testimonialsSliders.destroy(data?.current.container);
        accomodationPlaceSliders.destroy(data?.current.container);
        selects.destroy(data?.current.container);
        intlPhoneInputs.destroy(data?.current.container);
        document.body.classList.remove('no-scroll');
    });
}
