import type { InputMask } from 'imask';
import { fetchIMask } from '../dynamic-modules';

const map = new WeakMap<Element, InputMask<any>>();

function getInstanceByElement(element: Element) {
    return map.get(element);
}

export async function initPhoneMask(input: HTMLInputElement, dialCode = '7') {
    const IMask = await fetchIMask();
    input.value = input.value.trim().replace(new RegExp(`^\\+?${dialCode}`), '');

    const getMaskTemplate = (dialCode: string) => {
        switch (dialCode) {
            case '374':
                // Армения
                return `{${dialCode}} 00 00 00 00`;
            case '375':
                // Беларусь
                return `{${dialCode}} 00 000-00-00`;
            case '993':
                // Туркменистан
                return `{${dialCode}} 00 00 00 00`;
            case '994':
                // Азербайджан
                return `{${dialCode}} 00 000 00 00`;
            case '996':
                // Кыргызстан
                return `{${dialCode}} 00 000 00 00`;
            case '998':
                // Узбекистан
                return `{${dialCode}} 00 000 00 00`;
            default:
                // РФ, Казахстан
                return `{${dialCode}} (000) 000-00-00`;
        }
    };
    const imask = IMask(input, {
        mask: getMaskTemplate(dialCode),
        prepare: (value: string, maskedPattern: any) => {
            if (dialCode === '7') {
                if (maskedPattern.unmaskedValue.length === 0 && value === '8') {
                    return dialCode;
                } else {
                    return value;
                }
            }

            return value;
        },
    });
    map.set(input, imask);
}

export function destroyPhoneMask(input: HTMLInputElement) {
    const imask = getInstanceByElement(input);
    if (imask) {
        imask.destroy();
        map.delete(input);
    }
}

function initPhoneMasks(container: Element | Document = document) {
    const elements = Array.from(
        container.querySelectorAll<HTMLInputElement>('input[data-mask="phone"]:not(.js-intl-phone)'),
    );

    if (elements.length > 0) {
        elements.forEach((el) => {
            initPhoneMask(el);
        });
    }
}

async function initItnMasks(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLInputElement>('input[data-mask="itn"]'));

    if (elements.length > 0) {
        const IMask = await fetchIMask();
        elements.forEach((input) => {
            const imask = IMask(input, {
                mask: '0'.repeat(12),
            });
            map.set(input, imask);
        });
    }
}

async function initDateMasks(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLInputElement>('input[data-mask="date"]'));
    const nowYear = new Date().getFullYear();

    if (elements.length > 0) {
        const IMask = await fetchIMask();
        elements.forEach((input) => {
            const imask = IMask(input, {
                mask: Date,
                max: new Date(nowYear, 12, 12)
            });
            map.set(input, imask);
        });
    }
}

function init(container: Element | Document = document) {
    initPhoneMasks(container);
    initItnMasks(container);
    initDateMasks(container);
}

function destroy(container: Element | Document = document) {
    const phoneElements = Array.from(container.querySelectorAll<HTMLInputElement>('input[data-mask="phone"]'));
    const itnElements = Array.from(container.querySelectorAll<HTMLInputElement>('input[data-mask="itn"]'));
    const dateElements = Array.from(container.querySelectorAll<HTMLInputElement>('input[data-mask="date"]'));

    phoneElements.forEach((el) => {
        destroyPhoneMask(el);
    });

    itnElements.forEach((el) => {
        const imask = getInstanceByElement(el);
        if (imask) {
            imask.destroy();
            map.delete(el);
        }
    });

    dateElements.forEach((el) => {
        const imask = getInstanceByElement(el);
        if (imask) {
            imask.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
