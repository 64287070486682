const loaderTemplate = `
    <div class="loader" role="status">
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="35" r="34.5" stroke="#494A52" stroke-width="2" class="loader__circle" />
        </svg>
        <div class="visually-hidden">Загрузка...</div>
    </div>
`;

export function setButtonLoading(el: HTMLButtonElement) {
    const innerEl = el.querySelector('.btn__text');
    const rect = el.getBoundingClientRect();
    Object.assign(el.style, { width: `${rect.width}px`, height: `${rect.height}px` });
    if (innerEl) {
        el.dataset.actualText = innerEl.textContent || '';
        innerEl.innerHTML = loaderTemplate;
    }
    el.disabled = true;
}

export function removeButtonLoading(el: HTMLButtonElement) {
    const innerEl = el.querySelector('.btn__text');
    Object.assign(el.style, { width: '', height: '' });
    if (innerEl) {
        innerEl.textContent = el.dataset.actualText || '';
        el.removeAttribute('data-actual-text');
    }
    el.disabled = false;
}
