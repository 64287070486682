import gsap from 'gsap';
import { CountUp } from 'countup.js';
import barba from '@barba/core';
import lottie from 'lottie-web';

export const preloaderAnimationDuration = 0.7; // sec
const zero = document.querySelector<HTMLElement>('.js-preloader-counter-zero');

function createPreloader() {
    const preloader = document.querySelector<HTMLElement>('.js-preloader');
    const counterContainer = document.querySelector<HTMLElement>('.js-preloader-counter-container');
    const counter = document.querySelector<HTMLElement>('.js-preloader-counter');
    const background = document.querySelector<HTMLElement>('.js-preloader-background-progressbar');
    const lottieContainer = document.querySelector('.js-preloader-lottie-container');
    const snowflakeContainer = document.querySelector('.js-preloader-snowflake-container');

    let loaded = false;

    const state = {
        completed: false,
    };

    document.body.classList.add('no-scroll');

    function leave(): Promise<void> {
        return new Promise((resolve) => {
            document.body.classList.remove('no-scroll');

            document.dispatchEvent(new Event('preloader-leave'));

            barba.hooks.afterEnter(() => {
                if (!loaded) {
                    loaded = true;
                }
            });

            const tl = gsap.timeline();

            tl.to(counterContainer, { duration: 1, opacity: 0 })
                .to(
                    preloader,
                    {
                        duration: 0.5,
                        opacity: 0,
                        onComplete: () => {
                            preloader?.classList.add('is-hidden');
                            state.completed = true;
                        },
                    },
                    0.5,
                )
                .add(resolve, 0.65);
        });
    }

    function loadAsset(asset: HTMLImageElement | HTMLVideoElement): Promise<void> {
        return new Promise((resolve) => {
            if (asset instanceof HTMLImageElement) {
                if (asset.complete) {
                    resolve();
                } else {
                    asset.onload = () => resolve();
                    asset.onerror = () => resolve();
                }
            }

            if (asset instanceof HTMLVideoElement) {
                if (asset.readyState === 4) {
                    resolve();
                } else {
                    asset.addEventListener('canplay', () => resolve(), { once: true });
                }
            }
        });
    }
    const counterInstance = counter
        ? new CountUp(counter, 0, {
              startVal: 0,
              useEasing: true,
              duration: 2,
          })
        : null;

    // if (lottieContainer) {
    //     lottie.loadAnimation({
    //         container: lottieContainer,
    //         renderer: 'svg',
    //         loop: true,
    //         autoplay: true,
    //         path: '/local/templates/main/static/upload/preloader.json',
    //     });
    // }

    function setPercent(value: number) {
        counterInstance?.update(value);

        if (background) {
            background.style.transform = `scaleY(${value / 100})`;
        }
    }

    async function loadAssetsFromElement(element: Element | Document = document) {
        const images = Array.from(
            element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"]):not([data-src])'),
        );
        const videos = Array.from(element.querySelectorAll<HTMLImageElement>('video:not(.lazy):not([loading="lazy"])'));
        const assets: Array<HTMLImageElement | HTMLVideoElement> = [...images, ...videos];

        if (images.length > 0) {
            await Promise.all<any>(assets.map((asset) => loadAsset(asset)));
        }
    }

    async function loadAssets() {
        await loadAssetsFromElement(document.body);
        setPercent(100);
        if (zero) {
            setTimeout(() => {
                zero.style.opacity = '0';
            }, 1350);
        }
    }

    return { leave, loadAssets, state } as const;
}

export const preloader = createPreloader();
