import gsap from 'gsap';
import { ease2 } from '../utils/easings';

export function createInitialInnerPageTimeline(container: HTMLElement | Document = document) {
    const tl = gsap.timeline({
        paused: true,
        defaults: {
            duration: 1.017,
            ease: ease2,
        },
    });

    tl.fromTo(
        container.querySelectorAll('.js-initial-animation-inner__title'),
        { opacity: 0, y: 40 },
        { opacity: 1, y: 0, stagger: 0.067 },
    ).fromTo(
        container.querySelector('.js-initial-animation-index__scroll-down__text'),
        { opacity: 0, yPercent: 100 },
        { duration: 0.75, ease: 'power2.out', opacity: 1, yPercent: 0 },
        1.1,
    );

    return tl;
}
