import { AirDatepickerOptions, AirDatepickerPositionCallback } from 'air-datepicker';
import { fetchAirDatepicker } from '../dynamic-modules';

const map = new WeakMap<Element, any>();

export const calculateAirDatepickerPosition: AirDatepickerPositionCallback = ({ $datepicker, $target, $pointer }) => {
    const coords = $target.getBoundingClientRect();
    const dpWidth = $datepicker.clientWidth;
    const viewportWidth = window.innerWidth;
    const isOverflowing = coords.x + dpWidth > viewportWidth;
    const left = isOverflowing ? -(coords.x + dpWidth - viewportWidth) - 16 : 0;
    $datepicker.style.left = `${left}px`;
};

export const triggerFloatedLabelsUpdate = (input: HTMLInputElement) => {
    setTimeout(() => {
        if (input.value.trim().length > 0) {
            input.dispatchEvent(new Event('input'));
        }
    }, 1);
};

async function init(
    container: HTMLElement | Document = document,
    selector = 'input.js-datepicker',
    options?: Partial<AirDatepickerOptions>,
) {
    const elements = Array.from(container.querySelectorAll<HTMLInputElement>(selector));

    if (elements.length > 0) {
        const { default: AirDatepicker } = await fetchAirDatepicker();

        elements.forEach((el) => {
            const datepicker = new AirDatepicker(el, options);
            el.readOnly = true;
            el.autocomplete = 'off';
            map.set(el, datepicker);
            triggerFloatedLabelsUpdate(el);
        });
    }
}

function destroy(container: HTMLElement | Document = document, selector = 'input.js-datepicker') {
    Array.from(container.querySelectorAll<HTMLInputElement>(selector)).forEach((el) => {
        const datepicker = map.get(el);

        if (datepicker) {
            datepicker.destroy();
            el.readOnly = false;
            map.delete(el);
        }
    });
}

function getInstanceByElement(element?: Element | null) {
    return element ? map.get(element) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
