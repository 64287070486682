import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

function init(container: HTMLElement | Document = document) {
    const numbers = Array.from(container.querySelectorAll('.js-number-item'));

    function getMultiplier(i: number) {
        if (matchMedia('(max-width: 1024px)').matches || i % 6 === 0) return 0;
        return (numbers.length - 1 - i) * 0.016;
    }

    numbers.forEach((el, index) => {
        gsap.to(el, {
            scrollTrigger: {
                trigger: el,
                scrub: Math.random(),
            },
            y: () => -ScrollTrigger.maxScroll(window) * getMultiplier(index),
            ease: 'none',
        });
    });
}

function destroy(container: HTMLElement | Document = document) {
    //
}

const _module = { init, destroy };

export default _module;
