function changeHeaderLinks(container: HTMLElement | Document = document) {
    const json = container.querySelector('.js-header-links-json-data');

    if (json?.textContent) {
        const data = JSON.parse(json.textContent.trim());
        const headerProgramLinks = Array.from(document.querySelectorAll<HTMLElement>('.js-header-program-link'));
        const headerSpeakersLinks = Array.from(document.querySelectorAll<HTMLElement>('.js-header-speakers-link'));
        const headerTicketsLinks = Array.from(document.querySelectorAll<HTMLElement>('.js-header-tickets-link'));

        // dirty fix, delete later
        [...headerSpeakersLinks].forEach((link) => {
            const parent = link.closest<HTMLElement>('.header-nav-list__item');
            if ([''].includes(data.prefix)) {
                link.classList.add('header-link--hidden');

                if (parent) {
                    parent.style.display = 'none';
                }
            } else {
                link.classList.remove('header-link--hidden');

                if (parent) {
                    parent.style.display = '';
                }
            }
        });
        [...headerProgramLinks].forEach((link) => {
            const parent = link.closest<HTMLElement>('.header-nav-list__item');
            if ([''].includes(data.prefix)) {
                link.classList.add('header-link--hidden');

                if (parent) {
                    parent.style.display = 'none';
                }
            } else {
                link.classList.remove('header-link--hidden');

                if (parent) {
                    parent.style.display = '';
                }
            }
        });

        [...headerTicketsLinks].forEach((link) => {
            const elementToScroll = link.dataset.scrollto ? container.querySelector(link.dataset.scrollto) : null;

            if ([''].includes(data.prefix) && !elementToScroll) {
                link.classList.add('header-link--hidden');
            } else {
                if (elementToScroll) {
                    link.classList.remove('header-link--hidden');
                } else {
                    link.classList.add('header-link--hidden');
                }
            }
        });

        if (data.headerLinks.program) {
            headerProgramLinks.forEach((link) => {
                if (link instanceof HTMLAnchorElement) {
                    link.href = data.headerLinks.program;
                }
            });
        }

        if (data.headerLinks.speakers) {
            headerSpeakersLinks.forEach((link) => {
                if (link instanceof HTMLAnchorElement) {
                    link.href = data.headerLinks.speakers;
                }
            });
        }

        if (data.headerLinks.tickets) {
            headerTicketsLinks.forEach((link) => {
                if (link instanceof HTMLAnchorElement) {
                    link.href = data.headerLinks.tickets;
                }
            });
        }
    }
}

export default changeHeaderLinks;
