import { LitElement, css, html, unsafeCSS } from 'lit';
import Uppy, { UppyFile } from '@uppy/core';
import Form from '@uppy/form';
import XHRUpload from '@uppy/xhr-upload';

import _styles from './Upload.ce.scss';

export interface Upload {
    files: UppyFile<Record<string, unknown>, Record<string, unknown>>[];
    tempFilesEndpoint: string;
    uppy: Uppy;
    _input: HTMLInputElement | null;
    _isUploading: boolean;
}

// const dict = {
//     chooseFiles: 'Загрузить файл',
//     loading: 'Идет загрузка, подождите',
//     loadingError: 'Ошибка загрузки файла',
// };

/**
 * @attr {Boolean} multiple
 * @attr {String} name
 * @attr {String} tempFilesEndpoint
 *
 * @fires upload - Триггерится при начале загрузки файла.
 * @fires upload-success - Триггерится при успешной загрузке файла.
 * @fires complete - Триггерится при окончании загрузки файла (успешной или неудачной).
 *
 * @slot - Контент в формате HTML. Содержит (обязательно) <input type="file">.
 */
export class Upload extends LitElement {
    constructor() {
        super();
        this._onInputChange = this._onInputChange.bind(this);

        this.files = [];
        this._isUploading = false;
    }

    static get properties() {
        return {
            name: {
                type: String,
            },
            files: {
                type: Array,
            },
            tempFilesEndpoint: {
                type: String,
                attribute: 'temp-files-endpoint',
            },
            _isUploading: {
                type: Boolean,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        setTimeout(() => {
            this._input = this.querySelector<HTMLInputElement>('input[type="file"]');

            if (!this._input) {
                throw new Error('Input element not found');
            }

            this._input.classList.add('visually-hidden');

            this.uppy = new Uppy({
                pretty: false,
                autoProceed: true,
                allowMultipleUploadBatches: true,
                // maxNumberOfFiles: 40,
                // allowedFileTypes: [
                //     'image/*',
                //     '.doc',
                //     '.docx',
                //     '.rtf',
                //     '.xls',
                //     '.xlsx',
                //     '.pdf',
                //     '.xml,application/msword',
                //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                // ],
            });
            this.uppy.use(Form, {
                target: '.uppy-form',
                resultName: 'uppyResult',
                getMetaFromForm: true,
                addResultToForm: true,
                submitOnSuccess: false,
                triggerUploadOnSubmit: false,
            });

            this.uppy.use(XHRUpload, {
                endpoint: this.tempFilesEndpoint,
                formData: true,
                fieldName: this._input.name,
                // headers: {
                //     'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
                // },
            });

            this.uppy.on('upload', (data) => {
                this._isUploading = true;
                this.dispatchEvent(new CustomEvent('upload', { detail: data, composed: true }));
            });

            this.uppy.on('upload-success', (file, response) => {
                this.files.push(file);
                this.dispatchEvent(new CustomEvent('upload-success', { detail: file, composed: true }));
            });

            this.uppy.on('complete', (data) => {
                this._isUploading = false;
                // this._input.value = '';
                this.dispatchEvent(new CustomEvent('complete', { detail: data, composed: true }));
            });

            this._input.addEventListener('change', this._onInputChange);
        }, 1);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this._input?.removeEventListener('change', this._onInputChange);
        this.uppy.cancelAll();
        this.uppy.close();
    }

    render() {
        return html`
            <div>
                <slot></slot>
                ${this.files.length > 0
                    ? html`
                          <ul class="list">
                              ${this.files.map(
                                  (file) => html`
                                      <li class="list__item">
                                          <div class="file">
                                              <div class="file__content">
                                                  <div class="file__content-left">
                                                      <svg
                                                          width="20"
                                                          height="24"
                                                          viewBox="0 0 20 24"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                          <path
                                                              d="M19.083 5.043l-.32-.32L14.587.548l-.319-.32a.782.782 0 00-.55-.227H1.71C1.218 0 .689.379.689 1.21v21.962c0 .347.349.684.761.79.02.006.04.014.062.018.065.013.13.02.197.02H18.29c.066 0 .132-.007.197-.02.021-.004.04-.012.062-.017.412-.107.76-.444.76-.79V5.783c0-.317-.038-.552-.227-.741zm-1.248-.077h-3.49v-3.49l3.49 3.49zM1.709 23.172c-.03 0-.056-.01-.082-.02a.19.19 0 01-.11-.171v-6.016h16.966v6.016a.19.19 0 01-.11.171c-.026.01-.053.02-.082.02H1.709zm-.192-7.034V1.21c0-.09.014-.383.192-.383H13.54a.64.64 0 00-.024.161v4.804h4.804c.056 0 .109-.01.161-.024v10.369H1.518z"
                                                              fill="#181818"
                                                          />
                                                          <path
                                                              d="M7.12 18.515a1.422 1.422 0 00-.466-.255 1.683 1.683 0 00-.526-.084h-1.2v4.169h.68V20.84h.503c.219 0 .42-.032.6-.096s.335-.155.464-.271c.128-.117.228-.261.3-.433.071-.172.107-.363.107-.575 0-.2-.042-.38-.127-.54a1.33 1.33 0 00-.334-.41zm-.257 1.377a.62.62 0 01-.387.407.75.75 0 01-.246.043h-.623V18.69h.51c.173 0 .312.028.418.082a.678.678 0 01.362.458 1.273 1.273 0 01-.034.662zM11.223 18.786a1.917 1.917 0 00-.662-.444 2.39 2.39 0 00-.925-.166H8.38v4.169h1.578c.053 0 .134-.007.243-.02.11-.013.23-.043.362-.09a1.9 1.9 0 00.41-.213 1.44 1.44 0 00.382-.387c.114-.165.207-.368.28-.612.074-.243.111-.536.111-.88 0-.248-.043-.49-.13-.726a1.837 1.837 0 00-.393-.63zm-.495 2.62c-.204.294-.536.44-.996.44H9.06V18.69h.396c.324 0 .588.043.792.127.204.085.365.197.484.334.118.138.198.292.24.461.041.17.062.342.062.515 0 .558-.102.985-.305 1.279zM12.73 22.345h.69v-1.878h1.743v-.464h-1.742V18.69h1.917v-.514h-2.607v4.169zM13.901 9.501c-.38 0-.847.05-1.39.148-.757-.803-1.547-1.977-2.105-3.129.553-2.329.276-2.659.154-2.814-.13-.166-.313-.434-.521-.434-.088 0-.326.04-.42.07-.24.08-.367.264-.47.504-.293.684.109 1.85.522 2.748a27.777 27.777 0 01-1.568 4.449c-1.568.718-2.4 1.424-2.476 2.097-.028.245.03.604.461.928a.665.665 0 00.4.134c.362 0 .728-.277 1.151-.871.31-.434.64-1.025.987-1.76a19.818 19.818 0 013.652-1.167c.654.627 1.24.945 1.743.945.37 0 .688-.17.919-.493.24-.335.294-.636.161-.893-.16-.31-.552-.462-1.2-.462zm-7.404 4.003c-.193-.149-.182-.249-.178-.286.026-.23.386-.639 1.27-1.136-.67 1.238-1.03 1.402-1.092 1.422zm3.394-9.485c.017-.006.432.456.04 1.33-.59-.603-.081-1.316-.04-1.33zm-.856 6.631c.42-1 .81-2.106 1.107-3.13a14.167 14.167 0 001.581 2.295c-.883.207-1.823.5-2.688.835zm5.338-.198c-.127.178-.403.183-.5.183-.22 0-.303-.131-.64-.391.278-.036.54-.045.75-.045.367 0 .434.055.485.082a.643.643 0 01-.095.171z"
                                                              fill="#181818"
                                                          />
                                                      </svg>
                                                  </div>
                                                  <div class="file__content-right">
                                                      <div class="file__name">${file.name}</div>
                                                  </div>
                                              </div>
                                              <button
                                                  type="button"
                                                  class="underline-v2 file__remove-btn"
                                                  @click="${() => this._removeFileById(file.id)}"
                                              >
                                                  <svg
                                                      width="9"
                                                      height="9"
                                                      viewBox="0 0 9 9"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                      <path
                                                          d="M1 1L7.99738 7.99738"
                                                          stroke="#181818"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                      <path
                                                          d="M8 1L1.00262 7.99738"
                                                          stroke="#181818"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                      />
                                                  </svg>
                                              </button>
                                          </div>
                                      </li>
                                  `,
                              )}
                          </ul>
                      `
                    : ''}
            </div>
        `;
    }

    clear() {
        this.files.forEach((file) => {
            this.uppy.removeFile(file.id);
        });
        this.files = [];
    }

    _onInputChange(event: any) {
        const inputFiles = Array.from(event.target.files) as File[];

        inputFiles.forEach((file) => {
            try {
                this.uppy.addFile({
                    name: file.name,
                    type: file.type,
                    data: file,
                });
            } catch (err: any) {
                if (err.isRestriction) {
                    console.error('Restriction error:', err);
                }
                throw err;
            }
        });
    }

    _removeFileById(id: string) {
        this.uppy.removeFile(id);
        this.files = this.files.filter((file) => file.id !== id);

        if (this._input) {
            this._input.value = '';
        }
    }

    _getFileSize(fileSizeInBytes: number) {
        let measure = '';
        let power = 0;

        if (fileSizeInBytes < 1024) {
            measure = 'b';
            power = 0;
        } else if (fileSizeInBytes < 1024 ** 2) {
            measure = 'kb';
            power = 1;
        } else if (fileSizeInBytes < 1024 ** 3) {
            measure = 'mb';
            power = 2;
        } else if (fileSizeInBytes < 1024 ** 4) {
            measure = 'gb';
            power = 3;
        } else if (fileSizeInBytes < 1024 ** 5) {
            measure = 'tb';
            power = 4;
        }

        return (
            (fileSizeInBytes / 1024 ** power).toLocaleString('us', { maximumFractionDigits: 1 }).replace(',', '.') +
            measure
        );
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-upload': Upload;
    }
}
