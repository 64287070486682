import * as THREE from 'three';
let animationFrameId: number;
let scene: THREE.Scene;
let renderer: THREE.WebGLRenderer;
let sizes: { width: number; height: number };
let camera: THREE.PerspectiveCamera;
let canvasContainer: Element | null; 

function init(container: HTMLElement | Document = document) {
    canvasContainer = container.querySelector('.js-developers-webgl');
    if (canvasContainer) {
        // Сцена
        scene = new THREE.Scene();

        sizes = {
            width: canvasContainer.clientWidth,
            height: canvasContainer.clientHeight,
        };

        // Создание canvas и вставка в DOM
        renderer = new THREE.WebGLRenderer({ antialias: false });
        renderer.setPixelRatio(Math.min(devicePixelRatio, 2));
        renderer.setSize(sizes.width, sizes.height);
        const canvas = renderer.domElement;
        canvasContainer?.appendChild(canvas);

        // Камера
        camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
        camera.position.z = 10;
        camera.position.y = 1.1;
        camera.position.x = 0;
        scene.add(camera);

        window.addEventListener('resize', onWindowResize);

        const planeGeometry = new THREE.PlaneGeometry(20, 20, 150, 150);
        const planeMaterial = new THREE.ShaderMaterial({
            vertexShader: /*glsl*/ `
                uniform float uTime;
                uniform float uElevation;

                attribute float aSize;

                varying float vPositionY;
                varying float vPositionZ;

                void main() {
                    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
                    modelPosition.y = sin(modelPosition.x - uTime) * sin(modelPosition.z * 0.6 + uTime) * uElevation;

                    vec4 viewPosition = viewMatrix * modelPosition;
                    gl_Position = projectionMatrix * viewPosition;

                    gl_PointSize = 2.0 * aSize;
                    gl_PointSize *= ( 1.0 / - viewPosition.z );

                    vPositionY = modelPosition.y;
                    vPositionZ = modelPosition.z;
                }
            `,
            fragmentShader: /*glsl*/ `
                varying float vPositionY;
                varying float vPositionZ;

                void main() {
                    float strength = (vPositionY + 0.25) * 0.3;
                    gl_FragColor = vec4(1.0, 1.0, 1.0, strength);
                }
            `,
            uniforms: {
                uTime: { value: 0 },
                uElevation: { value: 0.482 },
            },
            transparent: true,
        });

        const planeSizesArray = new Float32Array(planeGeometry.attributes.position.count);
        for (let i = 0; i < planeSizesArray.length; i++) {
            planeSizesArray[i] = Math.random() * 4.0;
        }
        planeGeometry.setAttribute('aSize', new THREE.BufferAttribute(planeSizesArray, 1));

        const plane = new THREE.Points(planeGeometry, planeMaterial);
        plane.rotation.x = -Math.PI * 0.4;
        scene.add(plane);

        const clock = new THREE.Clock();

        const tick = () => {
            const elapsedTime = clock.getElapsedTime();
            planeMaterial.uniforms.uTime.value = elapsedTime;
            renderer.render(scene, camera);
            animationFrameId = window.requestAnimationFrame(tick);
        };

        tick();
    }
}

function destroy(container: HTMLElement | Document = document) {
    const canvasContainer = container.querySelector('.js-developers-webgl');
    if (canvasContainer) {
        const canvas = canvasContainer.querySelector('canvas');
        if (canvas) {
            window.cancelAnimationFrame(animationFrameId);
            canvasContainer.removeChild(canvas);
            window.removeEventListener('resize', onWindowResize);

            scene.traverse((object) => {
                if (object instanceof THREE.Mesh) {
                    if (object.geometry) {
                        object.geometry.dispose();
                    }
                    if (object.material) {
                        if (Array.isArray(object.material)) {
                            object.material.forEach((material) => material.dispose());
                        } else {
                            object.material.dispose();
                        }
                    }
                }
            });
            renderer.dispose();
        }
    }
}

function onWindowResize() {
    if (canvasContainer) {
        sizes.width = canvasContainer.clientWidth;
        sizes.height = canvasContainer.clientHeight;
    }

    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
}

const _module = { init, destroy };

export default _module;
