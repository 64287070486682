import './WebShare.scss';
import { LitElement, html, css, unsafeCSS, TemplateResult } from 'lit';
import { ok, mail, viber, whatsapp, telegram, tw, vk } from 'vanilla-sharing';
import _styles from './WebShare.ce.scss';
import { isMobileDevice } from '../../utils/mobile';
import loadScript from '../../../modules/load-script';

export interface WebShare {
    /**
     * Шерить ли в Facebook
     */
    facebook: boolean;

    /**
     * App ID для Facebook (получается в настройках бизнес-аккаунта FB)
     */
    facebookAppId: string | null;

    /**
     * Показывает, загружен ли Facebook SDK
     */
    facebookReady: boolean;

    /**
     * Шерить ли в Mail.ru
     */
    mail: boolean;

    /**
     * Использовать ли Web Share API (если доступно)
     */
    native: boolean;

    /**
     * Шерить ли в Одноклассники
     */
    ok: boolean;

    /**
     * Шерить ли в Telegram
     */
    telegram: boolean;

    /**
     * Заголовок для шеринга
     */
    title: string;

    /**
     * Шерить ли в Twitter
     */
    twitter: boolean;

    /**
     * Шерить ли в Skype
     */
    skype: boolean;

    /**
     * Показывает, загружен ли Skype SDK
     */
    skypeReady: boolean;

    /**
     * URL, который шерим
     */
    url: string;

    /**
     * Шерить ли в Viber
     */
    viber: boolean;

    /**
     * Шерить ли в VK
     */
    vk: boolean;

    /**
     * Шерить ли в Whatsapp
     */
    whatsapp: boolean;

    /**
     * Вычисленный boolean,
     * показывающий реальную возможность использовать WebShare API
     */
    _useNative: boolean;
}

function shareFacebook(url: string) {
    if ((window as any).FB) {
        (window as any).FB.ui({
            method: 'share',
            href: url,
        });
    }
}

function shareVK(url: string) {
    vk({
        url,
        title: document.title,
    });
}

function shareTwitter(url: string) {
    tw({
        url,
        title: document.title,
    });
}

function shareOK(url: string) {
    ok({
        url,
        title: document.title,
    });
}

function shareMail(url: string) {
    mail({
        url,
        title: document.title,
    });
}

function shareViber(url: string) {
    viber({
        url,
        title: document.title,
    });
}

function shareWhatsapp(url: string) {
    whatsapp({
        url,
        title: document.title,
    });
}

function shareTelegram(url: string) {
    telegram({
        url,
        title: document.title,
    });
}

function shareSkype(url: string) {
    telegram({
        url,
        title: document.title,
    });
}

function loadSkypeSharingSDK(): Promise<void> {
    return new Promise((resolve) => {
        ((r, d, s) => {
            (r as any).loadSkypeWebSdkAsync =
                (r as any).loadSkypeWebSdkAsync ||
                function (p) {
                    const sjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(p.id)) {
                        return;
                    }
                    const js = d.createElement(s) as HTMLScriptElement;
                    js.id = p.id;
                    js.src = p.scriptToLoad;
                    js.onload = p.callback;
                    sjs.parentNode.insertBefore(js, sjs);
                };
            const p = {
                scriptToLoad: 'https://swx.cdn.skype.com/shared/v/latest/skypewebsdk.js',
                id: 'skype_web_sdk',
                callback: resolve,
            };
            (r as any).loadSkypeWebSdkAsync(p);
        })(window, document, 'script');
    });
}

async function loadFacebookSharingSDK(fbAppId?: string | null) {
    if (!fbAppId) {
        console.error('Invalid Facebook App ID');
    }

    await loadScript(`https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0&appId=${fbAppId}`);
}

export class WebShare extends LitElement {
    constructor() {
        super();
        this.share = this.share.bind(this);
        this._closeOnOutsideClick = this._closeOnOutsideClick.bind(this);

        this._useNative = false;
        this.native = false;

        this.title = document.title;
        this.url = window.location.href;

        this.facebookReady = false;
        this.skypeReady = false;

        this.facebook = false;
        this.mail = false;
        this.ok = false;
        this.telegram = false;
        this.twitter = false;
        this.skype = false;
        this.viber = false;
        this.vk = false;
        this.whatsapp = false;

        this.facebookAppId = this.getAttribute('facebook-app-id');
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    static get properties() {
        return {
            facebook: {
                type: Boolean,
            },
            facebookAppId: {
                type: String,
            },
            mail: {
                type: Boolean,
            },
            native: {
                type: Boolean,
            },
            ok: {
                type: Boolean,
            },
            telegram: {
                type: Boolean,
            },
            twitter: {
                type: Boolean,
            },
            skype: {
                type: Boolean,
            },
            viber: {
                type: Boolean,
            },
            vk: {
                type: Boolean,
            },
            whatsapp: {
                type: Boolean,
            },
            _useNative: {
                type: Boolean,
            },
        };
    }

    connectedCallback() {
        super.connectedCallback();
        this._useNative = this.native && 'share' in navigator && isMobileDevice();

        if (!this._useNative) {
            if (this.facebook) {
                loadFacebookSharingSDK(this.facebookAppId).then(() => {
                    this.facebookReady = true;
                });
            }

            if (this.skype) {
                loadSkypeSharingSDK().then(() => {
                    this.skypeReady = true;
                });
            }
        }

        document.addEventListener('click', this._closeOnOutsideClick);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener('click', this._closeOnOutsideClick);
    }

    async share() {
        if (this._useNative) {
            await navigator.share({ text: '', title: this.title, url: this.url });
        } else {
            this.renderRoot.querySelector('.list')?.classList.toggle('is-open');
        }
    }

    private _closeOnOutsideClick(event: any) {
        const el = event.target.closest('app-web-share');

        if (!el) {
            this.renderRoot.querySelector('.list')?.classList.remove('is-open');
        }
    }

    /**
     *
     * Lit-шаблон для элемента списка
     *
     * @param iconHtml Lit-шаблон SVG-иконки
     * @param label Название соц. сети
     * @param sharingFn Релевантная функция шеринга
     * @returns TemplateResult<1>
     */
    li(iconHtml: TemplateResult<1>, label: string, sharingFn: any) {
        return html`
            <li class="list__item">
                <button
                    type="button"
                    class="list-btn"
                    aria-label="Поделиться в ${label}"
                    @click="${() => sharingFn(this.url)}"
                >
                    ${iconHtml}
                </button>
            </li>
        `;
    }

    render() {
        return html`
            <div class="text-md label">Поделиться:</div>
            <button
                type="button"
                class="list-btn ${this._useNative ? '' : 'list-btn--main'}"
                aria-label="Поделиться"
                @click="${this.share}"
            >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16 0.5C14.0759 0.5 12.5 2.07589 12.5 4C12.5 4.29288 12.5993 4.55473 12.668 4.82715L6.67285 7.82617C6.02956 7.03697 5.09075 6.5 4 6.5C2.07589 6.5 0.5 8.07589 0.5 10C0.5 11.9241 2.07589 13.5 4 13.5C5.09075 13.5 6.02956 12.963 6.67285 12.1738L12.668 15.1729C12.5993 15.4453 12.5 15.7071 12.5 16C12.5 17.9241 14.0759 19.5 16 19.5C17.9241 19.5 19.5 17.9241 19.5 16C19.5 14.0759 17.9241 12.5 16 12.5C14.9092 12.5 13.9704 13.037 13.3271 13.8262L7.33203 10.8271C7.40073 10.5547 7.5 10.2929 7.5 10C7.5 9.70712 7.40073 9.44527 7.33203 9.17285L13.3271 6.17383C13.9704 6.96303 14.9092 7.5 16 7.5C17.9241 7.5 19.5 5.92411 19.5 4C19.5 2.07589 17.9241 0.5 16 0.5ZM16 2C17.1135 2 18 2.88655 18 4C18 5.11345 17.1135 6 16 6C14.8865 6 14 5.11345 14 4C14 2.88655 14.8865 2 16 2ZM4 8C5.11345 8 6 8.88655 6 10C6 11.1135 5.11345 12 4 12C2.88655 12 2 11.1135 2 10C2 8.88655 2.88655 8 4 8ZM16 14C17.1135 14 18 14.8865 18 16C18 17.1135 17.1135 18 16 18C14.8865 18 14 17.1135 14 16C14 14.8865 14.8865 14 16 14Z"
                        fill="#94A1BD"
                    />
                </svg>
            </button>
            ${this._useNative
                ? ''
                : html`
                      <ul class="list">
                          ${this.facebook && this.facebookReady && this.facebookAppId
                              ? this.li(
                                    html`<svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.00878906 8.88932C0.00878906 3.97988 3.98794 0 8.89646 0C13.805 0 17.7841 3.97988 17.7841 8.88932C17.7841 13.5996 14.1212 17.4542 9.48897 17.7592V10.6672H11.859V9.48194H9.48897V7.70407C9.48897 6.72219 10.2848 5.92621 11.2665 5.92621H11.859V4.74097H11.2665C9.63033 4.74097 8.30395 6.0676 8.30395 7.70407V9.48194H5.9339V10.6672H8.30395V17.7592C3.67168 17.4542 0.00878906 13.5996 0.00878906 8.88932Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Facebook',
                                    shareFacebook,
                                )
                              : ''}
                          ${this.vk
                              ? this.li(
                                    html`<svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M1.68 1.68C0 3.376 0 6.088 0 11.52V12.48C0 17.904 0 20.616 1.68 22.32C3.376 24 6.088 24 11.52 24H12.48C17.904 24 20.616 24 22.32 22.32C24 20.624 24 17.912 24 12.48V11.52C24 6.096 24 3.384 22.32 1.68C20.624 0 17.912 0 12.48 0H11.52C6.096 0 3.384 0 1.68 1.68ZM4 7.208C4.128 13.448 7.248 17.192 12.72 17.192H12.728H13.04V13.624C15.048 13.824 16.568 15.296 17.176 17.192H20.016C19.7247 16.1146 19.2083 15.1112 18.5009 14.248C17.7935 13.3848 16.9111 12.6813 15.912 12.184C16.8073 11.6388 17.5806 10.9148 18.1835 10.0572C18.7864 9.19968 19.206 8.22701 19.416 7.2H16.84C16.28 9.184 14.616 10.984 13.04 11.152V7.208H10.456V14.12C8.856 13.72 6.84 11.784 6.752 7.208H4Z"
                                            fill="white"
                                        />
                                    </svg>`,
                                    'VK',
                                    shareVK,
                                )
                              : ''}
                          ${this.twitter
                              ? this.li(
                                    html`<svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.00878906 8.88932C0.00878906 3.97988 3.98794 0 8.89646 0C13.805 0 17.7841 3.97988 17.7841 8.88932C17.7841 13.5996 14.1212 17.4542 9.48897 17.7592V10.6672H11.859V9.48194H9.48897V7.70407C9.48897 6.72219 10.2848 5.92621 11.2665 5.92621H11.859V4.74097H11.2665C9.63033 4.74097 8.30395 6.0676 8.30395 7.70407V9.48194H5.9339V10.6672H8.30395V17.7592C3.67168 17.4542 0.00878906 13.5996 0.00878906 8.88932Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Twitter',
                                    shareTwitter,
                                )
                              : ''}
                          ${this.ok
                              ? this.li(
                                    html`<svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.00878906 8.88932C0.00878906 3.97988 3.98794 0 8.89646 0C13.805 0 17.7841 3.97988 17.7841 8.88932C17.7841 13.5996 14.1212 17.4542 9.48897 17.7592V10.6672H11.859V9.48194H9.48897V7.70407C9.48897 6.72219 10.2848 5.92621 11.2665 5.92621H11.859V4.74097H11.2665C9.63033 4.74097 8.30395 6.0676 8.30395 7.70407V9.48194H5.9339V10.6672H8.30395V17.7592C3.67168 17.4542 0.00878906 13.5996 0.00878906 8.88932Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Odnoklassniki',
                                    shareOK,
                                )
                              : ''}
                          ${this.telegram
                              ? this.li(
                                    html`<svg
                                        width="19"
                                        height="16"
                                        viewBox="0 0 19 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18.2985 0.772295C18.3331 0.559721 18.2553 0.344279 18.0942 0.20603C17.9331 0.0677815 17.7126 0.027394 17.5148 0.0998766L0.924484 6.17884C0.700955 6.26074 0.546639 6.4716 0.532333 6.71468C0.518026 6.95775 0.64649 7.18613 0.858788 7.29504L5.59888 9.72662C5.78906 9.82418 6.01561 9.80971 6.19252 9.6887L10.1273 6.99743L7.77123 10.0189C7.66749 10.152 7.62293 10.3236 7.64841 10.492C7.67388 10.6603 7.76706 10.81 7.90524 10.9045L15.0154 15.7677C15.1827 15.8821 15.3951 15.9017 15.5796 15.8197C15.7641 15.7377 15.8954 15.5653 15.9285 15.3618L18.2985 0.772295Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Telegram',
                                    shareTelegram,
                                )
                              : ''}
                          ${this.mail
                              ? this.li(
                                    html`<svg
                                        width="19"
                                        height="16"
                                        viewBox="0 0 19 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18.2985 0.772295C18.3331 0.559721 18.2553 0.344279 18.0942 0.20603C17.9331 0.0677815 17.7126 0.027394 17.5148 0.0998766L0.924484 6.17884C0.700955 6.26074 0.546639 6.4716 0.532333 6.71468C0.518026 6.95775 0.64649 7.18613 0.858788 7.29504L5.59888 9.72662C5.78906 9.82418 6.01561 9.80971 6.19252 9.6887L10.1273 6.99743L7.77123 10.0189C7.66749 10.152 7.62293 10.3236 7.64841 10.492C7.67388 10.6603 7.76706 10.81 7.90524 10.9045L15.0154 15.7677C15.1827 15.8821 15.3951 15.9017 15.5796 15.8197C15.7641 15.7377 15.8954 15.5653 15.9285 15.3618L18.2985 0.772295Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Mail',
                                    shareMail,
                                )
                              : ''}
                          ${this.viber
                              ? this.li(
                                    html`<svg
                                        width="19"
                                        height="16"
                                        viewBox="0 0 19 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18.2985 0.772295C18.3331 0.559721 18.2553 0.344279 18.0942 0.20603C17.9331 0.0677815 17.7126 0.027394 17.5148 0.0998766L0.924484 6.17884C0.700955 6.26074 0.546639 6.4716 0.532333 6.71468C0.518026 6.95775 0.64649 7.18613 0.858788 7.29504L5.59888 9.72662C5.78906 9.82418 6.01561 9.80971 6.19252 9.6887L10.1273 6.99743L7.77123 10.0189C7.66749 10.152 7.62293 10.3236 7.64841 10.492C7.67388 10.6603 7.76706 10.81 7.90524 10.9045L15.0154 15.7677C15.1827 15.8821 15.3951 15.9017 15.5796 15.8197C15.7641 15.7377 15.8954 15.5653 15.9285 15.3618L18.2985 0.772295Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Viber',
                                    shareViber,
                                )
                              : ''}
                          ${this.whatsapp
                              ? this.li(
                                    html`<svg
                                        width="19"
                                        height="16"
                                        viewBox="0 0 19 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18.2985 0.772295C18.3331 0.559721 18.2553 0.344279 18.0942 0.20603C17.9331 0.0677815 17.7126 0.027394 17.5148 0.0998766L0.924484 6.17884C0.700955 6.26074 0.546639 6.4716 0.532333 6.71468C0.518026 6.95775 0.64649 7.18613 0.858788 7.29504L5.59888 9.72662C5.78906 9.82418 6.01561 9.80971 6.19252 9.6887L10.1273 6.99743L7.77123 10.0189C7.66749 10.152 7.62293 10.3236 7.64841 10.492C7.67388 10.6603 7.76706 10.81 7.90524 10.9045L15.0154 15.7677C15.1827 15.8821 15.3951 15.9017 15.5796 15.8197C15.7641 15.7377 15.8954 15.5653 15.9285 15.3618L18.2985 0.772295Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Whatsapp',
                                    shareWhatsapp,
                                )
                              : ''}
                          ${this.skype && this.skypeReady
                              ? this.li(
                                    html`<svg
                                        width="19"
                                        height="16"
                                        viewBox="0 0 19 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18.2985 0.772295C18.3331 0.559721 18.2553 0.344279 18.0942 0.20603C17.9331 0.0677815 17.7126 0.027394 17.5148 0.0998766L0.924484 6.17884C0.700955 6.26074 0.546639 6.4716 0.532333 6.71468C0.518026 6.95775 0.64649 7.18613 0.858788 7.29504L5.59888 9.72662C5.78906 9.82418 6.01561 9.80971 6.19252 9.6887L10.1273 6.99743L7.77123 10.0189C7.66749 10.152 7.62293 10.3236 7.64841 10.492C7.67388 10.6603 7.76706 10.81 7.90524 10.9045L15.0154 15.7677C15.1827 15.8821 15.3951 15.9017 15.5796 15.8197C15.7641 15.7377 15.8954 15.5653 15.9285 15.3618L18.2985 0.772295Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Skype',
                                    shareSkype,
                                )
                              : ''}
                      </ul>
                  `}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-web-share': WebShare;
    }
}
