import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const timelinesMap = new WeakMap<Element, gsap.core.Tween>();
let timelineTween: gsap.core.Tween | null;

function init(container: HTMLElement | Document = document) {
    const titles = Array.from(container.querySelectorAll('.js-timeline-title'));
    const timelineEl = container.querySelector<HTMLElement>('.js-timeline');

    if (timelineEl) {
        timelineTween = gsap.to(timelineEl, {
            scrollTrigger: {
                trigger: timelineEl,
                start: 'top center',
                end: 'bottom bottom-=20%',
                onUpdate: ({ progress }) => {
                    timelineEl.style.setProperty('--timeline-progress', `${progress}`);
                },
            },
        });

        Array.from(container.querySelectorAll('.js-timeline-item__img')).forEach((el) => {
            const tween = gsap.to(el, {
                scrollTrigger: {
                    trigger: el,
                    scrub: true,
                },
                y: () => (matchMedia('(max-width: 1199px)').matches ? 0 : -ScrollTrigger.maxScroll(window) * 0.07),
                ease: 'none',
            });
            timelinesMap.set(el, tween);
        });
    }

    const timelineContainer = document.querySelector('.js-story-timeline-section');
    const markersContainer = document.querySelector('.js-progressbar-lines');
    const yearMarks = timelineContainer?.querySelectorAll('.js-timeline-year');

    yearMarks?.forEach((yearMark, index) => {
        const position = yearMark.offsetTop;
        const parentHeight = timelineContainer?.offsetHeight;
        const percentage = (position / parentHeight) * 100;
        const progressBarHeight = 50;
        const result = (percentage / 100) * progressBarHeight;
        const marker = document.createElement('div');
        const content = titles[index].textContent;

        marker.classList.add('marker', 'js-marker');
        marker.style.top = `${result}vh`;
        marker.style.setProperty('--content', "'" + content + "'");

        markersContainer?.appendChild(marker);
        const tween = gsap.to(marker, {
            onStart: function () {
                marker.classList.add('active-marker');
            },
            onReverseComplete: function () {
                marker.classList.remove('active-marker');
            },
            scrollTrigger: {
                trigger: yearMark,
                start: 'top center+=10%',
                end: 'top center+=10%',
                toggleActions: 'play none reverse none',
            },
        });
        timelinesMap.set(marker, tween);
    });
}

function destroy(container: HTMLElement | Document = document) {
    if (timelineTween) {
        timelineTween.kill();
        timelineTween = null;
    }

    Array.from(container.querySelectorAll('.js-timeline-item__img')).forEach((el) => {
        const tween = timelinesMap.get(el);

        if (tween) {
            tween.kill();
            timelinesMap.delete(el);
        }
    });

    Array.from(container.querySelectorAll('.js-marker')).forEach((el) => {
        const tween = timelinesMap.get(el);
        console.log(tween);
        if (tween) {
            tween.kill();
            timelinesMap.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
