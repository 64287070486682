import debounce from 'lodash.debounce';
import { fetchSwiper, fetchTinyColor } from '../dynamic-modules';
import { map, createCounter, createNav } from '../components/base-sliders';

const resizeMap = new WeakMap<Element, () => void>();

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-about-slider')).forEach((el) => {
        const isNarrowSlider = el.classList.contains('js-about-slider--three');
        const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

        async function initSlider() {
            if (!el.classList.contains('swiper-initialized')) {
                const [{ Swiper, Pagination, Autoplay, Lazy }, { default: tinycolor }] = await Promise.all([
                    fetchSwiper(),
                    fetchTinyColor(),
                ]);
                const aboutForumSection = el.closest<HTMLElement>('.js-about-forum-section');
                const nav = el.querySelector<HTMLElement>('.js-slider-nav');
                const counter = el.querySelector('.js-slider-counter');
                const progressbar = el.querySelector('.js-slider-progressbar');
                const autoplayDuration = 7000;

                const slider = new Swiper(el, {
                    modules: [Pagination, Autoplay, Lazy],
                    slidesPerView: 1,
                    speed: 1000,
                    loop: true,
                    centeredSlides: true,
                    autoplay: {
                        delay: autoplayDuration,
                        disableOnInteraction: false,
                        waitForTransition: false,
                    },
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true,
                        loadPrevNextAmount: 3,
                        checkInView: true,
                    },
                    pagination: {
                        el: '.js-pagination',
                        type: 'bullets',
                        clickable: true,
                        renderBullet: (index, className) =>
                            `<button class="link ${className}">${slides[index].dataset.name}</button>`,
                    },
                    breakpoints: {
                        1025: {
                            slidesPerView: isNarrowSlider ? 'auto' : 1,
                        },
                    },
                    on: {
                        beforeInit: (swiper) => {
                            createNav(swiper, nav);
                        },
                        init: (swiper) => {
                            createCounter(swiper, counter);
                            el.style.setProperty('--autoplay-duration', `${autoplayDuration}ms`);
                        },
                        slideChange: (swiper) => {
                            const { bgColor } = slides[swiper.realIndex].dataset;

                            if (bgColor && aboutForumSection) {
                                const color = tinycolor(bgColor);
                                aboutForumSection.style.backgroundColor = bgColor;

                                if (color.isLight()) {
                                    aboutForumSection.classList.add('is-light');
                                } else {
                                    aboutForumSection.classList.remove('is-light');
                                }
                            }

                            if (progressbar) {
                                progressbar.classList.remove('is-active');
                                setTimeout(() => {
                                    progressbar.classList.add('is-active');
                                }, 50);
                            }
                        },
                    },
                });
                map.set(el, slider);
            }
        }

        function destroySlider() {
            if (el.classList.contains('swiper-initialized')) {
                const slider = map.get(el);

                if (slider) {
                    slider.destroy();
                    map.delete(el);
                }
            }
        }

        function onResize() {
            if (slides.length > 1) {
                if (
                    el.classList.contains('js-about-slider--desktop')
                        ? !matchMedia('(max-width: 767px), (max-width: 900px) and (orientation: landscape)').matches
                        : true
                ) {
                    initSlider();
                } else {
                    destroySlider();
                }
            }
        }

        const debouncedOnResize = debounce(onResize, 100);
        map.set(el, debouncedOnResize);

        onResize();
        window.addEventListener('resize', debouncedOnResize);
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-about-slider')).forEach((el) => {
        const slider = map.get(el);
        const resizeFn = resizeMap.get(el);

        if (resizeFn) {
            window.removeEventListener('resize', resizeFn);
            resizeMap.delete(el);
        }

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
