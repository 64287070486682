import gsap from 'gsap';
import headerSizes from '../utils/headerSizes';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
const header = document.querySelector<HTMLElement>('.js-header');
import barba from '@barba/core';

gsap.registerPlugin(ScrollToPlugin);
function scrollToElement(element: HTMLElement | undefined | null) {
    if (element) {
        gsap.to(window, { scrollTo: element, offset: headerSizes.height * -1 - 20 });
    }
}

function onClick(e: Event) {
    e.preventDefault();
    const targetElement = e.target as HTMLElement;
    const linkElement = targetElement.closest<HTMLElement>('[data-scroll-to]');
    if (linkElement?.dataset && linkElement?.dataset.scrollTo) {
        const element = document.getElementById(linkElement.dataset.scrollTo);

        if (element) {
            scrollToElement(element);
        } else {
            barba.go(`/#${linkElement.dataset.scrollTo}`);
        }
    }
}

let timer: NodeJS.Timeout;

function init(container: HTMLElement | Document = document) {
    clearTimeout(timer);
    if (header) {
        const anchorLinks = Array.from(header.querySelectorAll('[data-scroll-to]'));
        anchorLinks.forEach((link) => link.addEventListener('click', onClick));
    }
    if (window.location.hash) {
        timer = setTimeout(() => {
            const element = container.querySelector<HTMLElement>(window.location.hash);
            scrollToElement(element);
        }, 1500);
    }
}
function destroy(container: HTMLElement | Document = document) {
    if (header) {
        const anchorLinks = Array.from(header.querySelectorAll('[data-scroll-to]'));
        anchorLinks.forEach((link) => link.removeEventListener('click', onClick));
        clearTimeout(timer);
    }
}

const _module = { init, destroy };

export default _module;
