import { fetchIntlTelInput } from '../dynamic-modules';
import { initPhoneMask, destroyPhoneMask } from '../inits/masks';

const map = new WeakMap<HTMLInputElement, intlTelInput.Plugin>();

async function init(container: HTMLElement | Document = document) {
    const intlInputs = Array.from(
        container.querySelectorAll<HTMLInputElement>('input.js-intl-phone:not(.intl--initialized)'),
    );
    if (intlInputs.length > 0) {
        const { default: intlTelInput } = await fetchIntlTelInput();

        intlInputs.forEach((input) => {
            const iti = intlTelInput(input, {
                formatOnDisplay: false,
                onlyCountries: ['ru', 'by', 'kz', 'uz', 'kg', 'az', 'am', 'tm'],
                preferredCountries: ['ru', 'kz'],
                initialCountry: 'ru',
                localizedCountries: {
                    ru: 'Россия',
                    by: 'Беларусь',
                    kz: 'Казахстан',
                    uz: 'Узбекистан',
                    kg: 'Кыргызстан',
                    az: 'Азербайджан',
                    am: 'Армения',
                    tm: 'Туркменистан',
                },
                utilsScript: `${PUBLIC_PATH}/utils.js`,
            });

            const setPhoneMask = () => {
                const data = iti.getSelectedCountryData();
                destroyPhoneMask(input);
                initPhoneMask(input, data.dialCode);
            };

            setPhoneMask();
            input.addEventListener('countrychange', setPhoneMask);
            map.set(input, iti);
            input.classList.add('intl--initialized');
        });
    }
}

function destroy(container: HTMLElement | Document = document) {
    const intlInputs = Array.from(container.querySelectorAll<HTMLInputElement>('input.js-intl-phone'));

    intlInputs.forEach((input) => {
        const iti = map.get(input);
        if (iti) {
            iti.destroy();
            map.delete(input);
        }
        input.classList.remove('intl--initialized');
    });
}

const _module = { init, destroy };

export default _module;
