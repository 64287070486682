const header = document.querySelector<HTMLElement>('.js-header');
let headerWidth = header?.offsetWidth || 0;
let headerHeight = header?.offsetHeight || 0;

window.addEventListener('resize', () => {
    headerWidth = header?.offsetWidth || 0;
    headerHeight = header?.offsetHeight || 0;
});

const headerSizes = {
    width: headerWidth,
    height: headerHeight,
};

export default headerSizes;
