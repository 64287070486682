import { fetchAirDatepicker } from '../dynamic-modules';
import { calculateAirDatepickerPosition, triggerFloatedLabelsUpdate } from './datepicker';

/**
 * Создает дату из строки "dd.mm.yyyy"
 *
 * @param dateString строка "dd.mm.yyyy"
 * @returns Date
 */
function createDateFromString(dateString: string): Date {
    const parts = dateString.split('.');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
}

/**
 * Создает строку в формате "dd.mm.yyyy" из объекта даты
 *
 * @param dateString строка "dd.mm.yyyy"
 * @returns {String}
 */
function createStringFromDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
}

/**
 * Возращает ближайшую по времени дату
 *
 * @param dates массив дат
 * @returns Date
 */
const getEarliestDate = (...dates: Date[]) => dates.sort((a, b) => a.getTime() - b.getTime())[0];

/**
 * Возращает самую дальнюю по времени дату
 *
 * @param dates массив дат
 * @returns Date
 */
const getLatestDate = (...dates: Date[]) => dates.sort((a, b) => b.getTime() - a.getTime())[0];

const map = new Map<HTMLInputElement, any>();

function init(container: HTMLElement | Document = document) {
    const datepickers = Array.from(
        container.querySelectorAll<HTMLInputElement>('input.js-datepicker-find-accomodation'),
    );

    datepickers.forEach((datepicker) => {
        fetchAirDatepicker().then(({ default: AirDatepicker }) => {
            const today = new Date();
            const possibleMinDate = datepicker.dataset.min ? createDateFromString(datepicker.dataset.min) : new Date();
            const possibleMaxDate = datepicker.dataset.max ? createDateFromString(datepicker.dataset.max) : undefined;
            const bookedDates = datepicker.dataset.booked ? (JSON.parse(datepicker.dataset.booked) as string[]) : [];

            const datepickerInstance = new AirDatepicker(datepicker, {
                container: datepicker.closest<HTMLElement>('.input-group')!,
                minDate: possibleMinDate.getTime() > today.getTime() ? possibleMinDate : today,
                maxDate: possibleMaxDate,
                position: calculateAirDatepickerPosition,
                range: true,
                multipleDatesSeparator: ' - ',
                onRenderCell: ({ date, cellType }) => {
                    if (cellType === 'day') {
                        if (bookedDates.includes(createStringFromDate(date))) {
                            return { disabled: true };
                        }
                    }
                },
                // onSelect: ({ date }) => {
                //     if (date instanceof Date) {
                //         const prevDateTo = createDateFromString(datepickerTo.value);
                //         const minDate = getLatestDate(date, possibleMinDate);
                //         datepickerToInstance.update({ minDate });
                //         datepickerTo.value = createStringFromDate(getLatestDate(minDate, prevDateTo));
                //         triggerFloatedLabelsUpdate(datepickerTo);
                //     }
                // },
            });
            datepicker.readOnly = true;
            datepicker.autocomplete = 'off';
            map.set(datepicker, datepickerInstance);
        });
    });
}

function destroy(container: HTMLElement | Document = document) {
    //
}

function getInstanceByElement(el?: HTMLInputElement | null) {
    return el ? map.get(el) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
