function headerActiveLinks(container: HTMLElement | Document = document) {
    const navLinks = Array.from(document.querySelectorAll<HTMLAnchorElement>("a.js-header-nav-link"));
    const locationHref = document.location.href;

    if (locationHref && navLinks) {
        navLinks.forEach((link, i) => {
            if (locationHref.indexOf(link.pathname) > -1 && link.pathname !== "/") {
                link.classList.add("is-active");
            } else {
                link.classList.remove("is-active");
            }
        })
    }
}

export default headerActiveLinks;
