import type { ContentList as ContentListType } from '@chipsadesign/content-list';
import { fetchContentList, fetchContentListFilter } from '../dynamic-modules';

const header = document.querySelector('.js-header');
let toggleBtn: HTMLElement | null;
let filterContainer: HTMLElement | null;
let ordersHistoryEl: HTMLElement | null;
let orderHistoryContentList: ContentListType | null;

function toggleFilter() {
    toggleBtn?.classList.toggle('is-filter-visible');
    filterContainer?.classList.toggle('is-visible');
}

function init(container: HTMLElement | Document = document) {
    toggleBtn = container.querySelector<HTMLElement>('.js-account-orders-filter-toggle-btn');
    filterContainer = container.querySelector<HTMLElement>('.js-account-orders-filter');
    ordersHistoryEl = container.querySelector<HTMLElement>('.js-orders-history');
    toggleBtn?.addEventListener('click', toggleFilter);

    if (ordersHistoryEl) {
        Promise.all([fetchContentList(), fetchContentListFilter()]).then(([ContentList, ContentListFilter]) => {
            if (ordersHistoryEl) {
                orderHistoryContentList = new ContentList(ordersHistoryEl, {
                    modules: [ContentListFilter],
                    pushState: false,
                    cacheRequests: true,
                    scrollTo: {
                        el: filterContainer || ordersHistoryEl,
                        offset: (header ? -header.getBoundingClientRect().height : 0) - 30,
                    },
                    onFetchSuccess: (instance, response) => {
                        if (response.data) {
                            if (response.data.contentHtml && instance.contentEl) {
                                instance.contentEl.innerHTML = response.data.contentHtml;
                            }

                            if (response.data.nextUrl) {
                                window.history.pushState(null, '', response.data.nextUrl);
                            }
                        }
                    },
                    filter: {
                        el: filterContainer,
                        filterOnInputChange: true,
                    },
                });
            }
        });
    }
}

function destroy() {
    if (orderHistoryContentList) {
        orderHistoryContentList.destroy();
        orderHistoryContentList = null;
    }

    if (toggleBtn) {
        toggleBtn.removeEventListener('click', toggleFilter);
        toggleBtn = null;
        filterContainer = null;
    }
}

const _module = { init, destroy };

export default _module;
